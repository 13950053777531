import React, { useState } from "react";
import AddDailyValue from "../addDailyValue";
import { IconExport } from "../../../../Assets/Icons";
import ExportData from "../../../../Components/exportData";

// Interface for value data
interface ValueEntry {
  id: number;
  week: number;
  value: number;
}

// Interface for student data
interface DailyValue {
  id: number;
  studentName: string;
  value: ValueEntry[];
}

// Static data for demonstration
const initialValues: DailyValue[] = [
  {
    id: 1,
    studentName: "Alice Johnson",
    value: [
      { id: 1, week: 1, value: 85 },
      { id: 2, week: 2, value: 90 },
      { id: 3, week: 3, value: 88 },
      { id: 4, week: 4, value: 92 },
    ],
  },
  {
    id: 2,
    studentName: "Bob Smith",
    value: [
      { id: 1, week: 1, value: 78 },
      { id: 2, week: 2, value: 83 },
      { id: 3, week: 3, value: 80 },
      { id: 4, week: 4, value: 85 },
    ],
  },
  {
    id: 3,
    studentName: "Charlie Brown Alexander",
    value: [
      { id: 1, week: 1, value: 92 },
      { id: 2, week: 2, value: 88 },
      { id: 3, week: 3, value: 91 },
      { id: 4, week: 4, value: 95 },
    ],
  },
];

const calculateAverages = (values: DailyValue[]) => {
  const weekSums: { [key: number]: number } = {};
  const weekCounts: { [key: number]: number } = {};
  let totalMonthlySum = 0;
  let totalStudentCount = 0;

  // Calculate week-wise and student-wise totals
  const studentAverages: { [key: number]: number } = {};

  values.forEach((student) => {
    let studentMonthlySum = 0;
    student.value.forEach((entry) => {
      const week = entry.week;
      const value = entry.value;
      weekSums[week] = (weekSums[week] || 0) + value;
      weekCounts[week] = (weekCounts[week] || 0) + 1;
      studentMonthlySum += value;
    });
    studentAverages[student.id] = studentMonthlySum / student.value.length;
    totalMonthlySum += studentMonthlySum;
    totalStudentCount++;
  });

  const weekAverages: { [key: number]: number } = {};
  Object.keys(weekSums).forEach((week) => {
    weekAverages[Number(week)] =
      weekSums[Number(week)] / (weekCounts[Number(week)] || 1);
  });

  const monthlyAverage =
    totalStudentCount > 0 ? totalMonthlySum / (totalStudentCount * 4) : 0; // 4 weeks per student

  return { weekAverages, monthlyAverage, studentAverages };
};

const DailyValue: React.FC = () => {
  const { weekAverages, monthlyAverage, studentAverages } =
    calculateAverages(initialValues);
  const [showAdd, setShowAdd] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const handleOpenAdd = () => {
    setShowAdd(true);
  };

  const handleDropdownToggle = (dropdownName: string) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };
  const handleCloseAdd = () => {
    setShowAdd(false);
  };
  return (
    <div className="p-4 font-inter bg-gray-50 min-h-screen">
      <div className="mb-4 text-gray-800">
        <h1 className="text-2xl mb-4 font-roboto font-bold">
          Penilaian Harian
        </h1>
        <div className="flex">
          <div className="flex-shrink-0 pr-6">
            <p className="font-medium">Jurusan</p>
            <p className="font-medium">Kelas</p>
            <p className="font-medium">Wali Kelas</p>
          </div>
          <div>
            <p className="ml-2">: MIPA</p>
            <p className="ml-2">: 10.Mipa 6</p>
            <p className="ml-2">
              : <span className="font-semibold">H. Nasar</span>
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <div onClick={handleOpenAdd}>
          <button>Tambah Nilai Siswa</button>
        </div>
        <div
          onClick={() => handleDropdownToggle("export")}
          className="flex border border-slate-100 shadow-sm rounded-lg gap-[10px] px-[15px] py-[10px] cursor-pointer bg-gradient-to-r from-[#EF2725] to-[#F26023] "
        >
          <div className="py-[3px]">{IconExport("16", "16", "white")}</div>
          <p className="text-[14px] text-white font-inter font-semibold ">
            Export
          </p>
        </div>
        {openDropdown === "export" && (
          <div className="absolute right-[14px] mt-[53px]">
            <ExportData onClose={() => setOpenDropdown(null)} />
          </div>
        )}
      </div>
      <div>{showAdd && <AddDailyValue onClose={handleCloseAdd} />}</div>
      <div className="mb-6">
        <div className="overflow-x-auto ">
          <table className="w-[700px] bg-white border border-gray-300 rounded-lg shadow-md">
            <thead className="bg-gray-200">
              <tr>
                <th className="p-3 border-b text-left text-gray-600">ID</th>
                <th className="p-3 border-b  text-left text-gray-600">
                  Nama Lengkap
                </th>
                <th className="p-3 w-[100px] border-b text-left text-gray-600">
                  Minggu 1
                </th>
                <th className="p-3 border-b w-[100px] text-left text-gray-600">
                  Minggu 2
                </th>
                <th className="p-3 border-b w-[100px] text-left text-gray-600">
                  Minggu 3
                </th>
                <th className="p-3 border-b w-[100px] text-left text-gray-600">
                  Minggu 4
                </th>
                <th className="p-3 border-b w-[100px] text-left text-gray-600">
                  Rata-rata
                </th>
              </tr>
            </thead>
            <tbody>
              {initialValues.map((student) => {
                const weekValues = student.value.reduce((acc, curr) => {
                  acc[`week${curr.week}`] = curr.value;
                  return acc;
                }, {} as { [key: string]: number });

                return (
                  <tr key={student.id} className="hover:bg-gray-100">
                    <td className="p-3 border-b text-gray-700">{student.id}</td>
                    <td className="p-3 border-b  text-gray-700">
                      {student.studentName}
                    </td>
                    <td className="p-3 border-b text-center text-gray-700">
                      {weekValues.week1 || "-"}
                    </td>
                    <td className="p-3 border-b text-center text-gray-700">
                      {weekValues.week2 || "-"}
                    </td>
                    <td className="p-3 border-b text-center text-gray-700">
                      {weekValues.week3 || "-"}
                    </td>
                    <td className="p-3 border-b text-center text-gray-700">
                      {weekValues.week4 || "-"}
                    </td>{" "}
                    <td className="p-3 border-b text-center text-gray-700">
                      {studentAverages[student.id].toFixed(2)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <h2 className="text-xl font-semibold mb-4 text-gray-700">Averages</h2>
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-3 border-b text-left text-gray-600">Minggu</th>
              <th className="p-3 border-b text-left text-gray-600">
                Nilai Rata-rata
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(weekAverages).map((week) => (
              <tr key={week} className="hover:bg-gray-100">
                <td className="p-3 border-b text-gray-700">{`Minggu ${week}`}</td>
                <td className="p-3 border-b text-gray-700">
                  {weekAverages[Number(week)].toFixed(2)}
                </td>
              </tr>
            ))}
            <tr className="bg-gray-200 font-bold">
              <td className="p-3 border-b text-gray-700">
                Rata-rata per bulan
              </td>
              <td className="p-3 border-b text-gray-700">
                {monthlyAverage.toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DailyValue;
