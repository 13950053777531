import React, { useState } from "react";
import { IconSearch2 } from "../../ListBooks/icons";
import Slide from "../../../Components/slide";
import ConsolidationArtikel from "./ConsolidationArtikel";
import FAQSection from "./paqConsolidation";
import ComplainForm from "./complainConsolidation";
import { IconCustomer } from "../../../Assets/Icons";

const Consolidation: React.FC = () => {
  const [isComplainFormVisible, setComplainFormVisible] = useState(false);

  const toggleComplainForm = () => {
    setComplainFormVisible(!isComplainFormVisible);
  };

  return (
    <div className="relative lg:ml-[10px] lg:absolute font-inter max-w-5xl mx-auto bg-gray-50">
      {/* Fixed Header */}
      <div className="fixed lg:mt-[-12px] bg-gray-50 z-20 w-full flex justify-between py-2 shadow border-b border-slate-300 px-4">
        <h1 className="text-[20px] font-bold text-gray-800">Konseling</h1>
        <div>{IconSearch2("24", "24", "gray")}</div>
      </div>
      {/* Main Content */}
      <div className="pt-[60px] lg:pt-[40px] pb-16">
        <Slide />
        <ConsolidationArtikel />
        <FAQSection />
      </div>
      {/* Fixed Icon and Conditional Complain Form */}
      <div className="fixed bottom-[70px] right-4  z-30 flex flex-col items-center">
        <button
          onClick={toggleComplainForm}
          className="bg-blue-500 p-3  w-full rounded-full shadow-lg flex items-center justify-center"
        >
          {IconCustomer("50", "50", "white")}
        </button>
        <p className="text-blue-500 ">Konseling</p>
      </div>
      <div className="fixed bottom-[70px]   z-30 flex flex-col items-center">
        {isComplainFormVisible && (
          <div className="w-full mx-auto flex justify-center ">
            {isComplainFormVisible && (
              <ComplainForm onClose={toggleComplainForm} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Consolidation;
