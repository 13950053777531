import React, { useState } from "react";
import { IconChat, IconLike } from "../../Assets/Icons";
import CommentPost from "./CommentPost";

const Post: React.FC = () => {
  // Sample data, you can replace this with actual data
  const posts = [
    {
      id: 1,
      schoolName: "SMAN 1 Pedes Karawang",
      content: "Kegiatan Mendaria bakkat seseoranf",
      timeAgo: "2 jam yang lalu",
      comments: [
        {
          id: 1,
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ",
          userName: "User1",
          timeAgo: "1 jam yang lalu",
          replies: [
            {
              id: 1,
              content: "Reply to User1",
              userName: "User3",
              timeAgo: "10 menit yang lalu",
            },
          ],
        },
        {
          id: 2,
          content: "I agree with this.",
          userName: "User2",
          timeAgo: "30 menit yang lalu",
          replies: [],
        },
      ],
      likes: 30,
      image: "https://picsum.photos/200/300?random=1",
    },
    {
      id: 1,
      schoolName: "SMAN 1 Pedes Karawang",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ex provident qui, alias laborum rerum voluptas nihil.",
      timeAgo: "2 jam yang lalu",
      image: "https://picsum.photos/200/300?random=2",
      comments: [
        {
          id: 1,
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ",
          userName: "User1",
          timeAgo: "1 jam yang lalu",
          replies: [
            {
              id: 1,
              content: "Reply to User1",
              userName: "User3",
              timeAgo: "10 menit yang lalu",
            },
          ],
        },
        {
          id: 2,
          content: "I agree with this.",
          userName: "User2",
          timeAgo: "30 menit yang lalu",
          replies: [],
        },
      ],
      likes: 30,
    },
    {
      id: 1,
      schoolName: "SMAN 1 Pedes Karawang",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ex provident qui, alias laborum rerum voluptas nihil.",
      timeAgo: "2 jam yang lalu",
      image: "https://picsum.photos/200/300?random=3",
      comments: [
        {
          id: 1,
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ",
          userName: "User1",
          timeAgo: "1 jam yang lalu",
          replies: [
            {
              id: 1,
              content: "Reply to User1",
              userName: "User3",
              timeAgo: "10 menit yang lalu",
            },
          ],
        },
        {
          id: 2,
          content: "I agree with this.",
          userName: "User2",
          timeAgo: "30 menit yang lalu",
          replies: [],
        },
      ],
      likes: 30,
    },
    {
      id: 1,
      schoolName: "SMAN 1 Pedes Karawang",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ex provident qui, alias laborum rerum voluptas nihil.",
      timeAgo: "2 jam yang lalu",
      image: "https://picsum.photos/200/300?random=8",
      comments: [
        {
          id: 1,
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ",
          userName: "User1",
          timeAgo: "1 jam yang lalu",
          replies: [
            {
              id: 1,
              content: "Reply to User1",
              userName: "User3",
              timeAgo: "10 menit yang lalu",
            },
          ],
        },
        {
          id: 2,
          content: "I agree with this.",
          userName: "User2",
          timeAgo: "30 menit yang lalu",
          replies: [],
        },
      ],
      likes: 30,
    },
    {
      id: 1,
      schoolName: "SMAN 1 Pedes Karawang",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ex provident qui, alias laborum rerum voluptas nihil.",
      timeAgo: "2 jam yang lalu",
      image: "https://picsum.photos/200/300?random=4",
      comments: [
        {
          id: 1,
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ",
          userName: "User1",
          timeAgo: "1 jam yang lalu",
          replies: [
            {
              id: 1,
              content: "Reply to User1",
              userName: "User3",
              timeAgo: "10 menit yang lalu",
            },
          ],
        },
        {
          id: 2,
          content: "I agree with this.",
          userName: "User2",
          timeAgo: "30 menit yang lalu",
          replies: [],
        },
      ],
      likes: 30,
    },
    // Additional posts can be added here
  ];

  const [likedPosts, setLikedPosts] = useState<number[]>([]);
  const [showFullText, setShowFullText] = useState<number[]>([]);
  const [selectedPostId, setSelectedPostId] = useState<number | null>(null);

  const handleLikeClick = (id: number) => {
    if (likedPosts.includes(id)) {
      setLikedPosts(likedPosts.filter((postId) => postId !== id));
    } else {
      setLikedPosts([...likedPosts, id]);
    }
  };

  const toggleFullText = (id: number) => {
    if (showFullText.includes(id)) {
      setShowFullText(showFullText.filter((postId) => postId !== id));
    } else {
      setShowFullText([...showFullText, id]);
    }
  };

  const handleCommentsClick = (id: number) => {
    setSelectedPostId(id);
  };

  const closeComments = () => {
    setSelectedPostId(null);
  };

  return (
    <section className="flex  font-roboto flex-col gap-[10px]">
      {posts.map((post) => (
        <div
          key={post.id}
          className="px-[15px] py-[10px] bg-slate-100 border rounded-lg"
        >
          <div className="flex gap-2 items-center">
            <div className="w-[60px] h-[60px]">
              <img
                src={post.image}
                alt=""
                className="w-full h-full rounded-full "
              />
            </div>
            <div className="flex flex-col justify-center">
              <p className="text-[14px] font-bold font-inter">
                {post.schoolName}
              </p>{" "}
              <div className="flex gap-5 items-center">
                <p className="text-[12px] font-semibold text-gray-800 font-roboto">
                  Abdul Majid
                </p>
                <p className="text-[10px]  text-slate-500">Publik</p>
              </div>
            </div>
          </div>

          <div className="h-auto">
            <div className="flex flex-col  justify-center items-center w-full">
              <div className="my-[10px]   w-full lg:w-[600px] lg:h-[300px] h-[200px]">
                <img
                  src={post.image}
                  alt=""
                  className="object-cover h-full rounded-lg w-full"
                />
              </div>
            </div>
            <div>
              <p className="text-[13px] font-inter  leading-[17px]">
                {showFullText.includes(post.id) || post.content.length <= 120
                  ? post.content
                  : `${post.content.slice(0, 120)}... `}
                {post.content.length > 120 && (
                  <span
                    className="text-blue-600 cursor-pointer"
                    onClick={() => toggleFullText(post.id)}
                  >
                    {showFullText.includes(post.id)
                      ? "Sembunyikan"
                      : "Selengkapnya"}
                  </span>
                )}
              </p>
            </div>
            <div className="flex justify-between mt-[10px]">
              <p className="text-[10px] text-slate-500 mt-[5px]">
                {post.timeAgo}
              </p>
              <div className="flex gap-5 ">
                <div
                  className="flex gap-1"
                  onClick={() => handleCommentsClick(post.id)}
                >
                  {IconChat("20", "20", "gray")}{" "}
                  <p className="mt-[-2px]">{post.comments.length}</p>
                </div>
                <div
                  className="flex gap-1"
                  onClick={() => handleLikeClick(post.id)}
                >
                  {IconLike(
                    "20",
                    "20",
                    likedPosts.includes(post.id) ? "blue" : "gray"
                  )}
                  <p className="mt-[-2px]">{post.likes}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {selectedPostId && (
        <CommentPost
          comments={
            posts
              .find((post) => post.id === selectedPostId)
              ?.comments.map((comment) => ({
                ...comment,
                replies: comment.replies.map((reply) => ({
                  ...reply,
                  replies: [], // Initialize replies for each reply as well
                })),
              })) || []
          }
          onClose={closeComments}
        />
      )}
    </section>
  );
};

export default Post;
