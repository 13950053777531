export const IconRansel = (width: string, height: string, fill: string) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <g transform="translate(1 1)">
      <g>
        <g>
          <path
            d="M343.615,402.692H166.385c-5.908,0-9.846,3.938-9.846,9.846v39.385c0,5.908,3.938,9.846,9.846,9.846h177.231
                  c5.908,0,9.846-3.938,9.846-9.846v-39.385C353.462,406.631,349.523,402.692,343.615,402.692z M333.769,442.077H176.231v-19.692
                  h157.538V442.077z"
          />
          <path
            d="M473.585,352.477h-15.713L443.062,165.4c-4.923-56.123-51.2-97.477-107.323-97.477h-0.985h-1.68
                  c-4.702-33.64-33.176-59.077-68.228-59.077h-19.692c-35.057,0-63.534,25.444-68.23,59.092
                  c-54.574,0.633-100.185,41.765-106.047,96.477c0,0.62,0.004,1.138,0.034,1.624c-0.01,0.115-0.024,0.229-0.034,0.345
                  L52.645,353.462H36.415C15.738,353.462-1,370.2-1,390.877v33.477c0,37.415,29.538,66.954,66.954,66.954h33.871
                  c12.788,6.447,27.057,9.846,41.945,9.846h231.385c14.497,0,28.65-3.418,41.257-9.846h29.635
                  c37.415,0,66.954-29.538,66.954-67.938v-33.477C511,369.215,494.262,352.477,473.585,352.477z M353.462,156.538
                  c5.908,0,9.846-3.938,9.846-9.846c0-5.908-3.938-9.846-9.846-9.846h-39.385c-5.908,0-9.846,3.938-9.846,9.846
                  c0,5.908,3.938,9.846,9.846,9.846h9.846v104.079c-21.327,9.1-44.658,14.075-68.923,14.075c-24.302,0-47.616-4.826-68.923-13.734
                  v-104.42h9.846c5.908,0,9.846-3.938,9.846-9.846c0-5.908-3.938-9.846-9.846-9.846h-39.385c-5.908,0-9.846,3.938-9.846,9.846
                  c0,5.908,3.938,9.846,9.846,9.846h9.846v94.703c-33.477-19.213-60.367-49.308-75.815-86.827
                  c5.722-42.912,40.997-74.74,83.465-76.703c1.381-0.062,2.769-0.097,4.166-0.097v0h156.554c1.371,0,2.733,0.035,4.088,0.095
                  c0.777,0.035,1.548,0.097,2.32,0.152c0.537,0.037,1.077,0.066,1.611,0.113c1.125,0.101,2.244,0.225,3.357,0.367
                  c0.135,0.017,0.27,0.03,0.405,0.047c37.008,4.896,66.84,32.572,74.866,69.133c-14.66,40.034-42.563,72.635-77.785,93.193v-94.177
                  H353.462z M245.154,28.538h19.692c23.631,0,43.323,16.738,48.246,39.385H196.908C201.831,45.277,221.523,28.538,245.154,28.538z
                   M18.692,424.354v-33.477c0-9.846,7.877-17.723,17.723-17.723h14.31l-2.495,25.6c-0.19,2.477-0.277,4.962-0.267,7.45
                  c-0.011,23.164,7.782,44.636,22.765,61.982c0.693,0.825,1.394,1.643,2.116,2.445c0.3,0.333,0.606,0.657,0.91,0.985h-7.802
                  C39.369,471.615,18.692,450.938,18.692,424.354z M142.754,481.462c-20.677,0-41.354-8.862-55.138-24.615
                  c-14.769-14.769-21.662-35.446-19.692-56.123l3.938-36.431c0-0.926-0.108-1.849-0.302-2.744l15.047-162.099
                  c19.272,32.165,47.013,57.663,79.778,74.101v40.527h-9.846l-9.846,9.846v39.385l9.846,9.846h39.385l9.846-9.846v-39.385
                  l-9.846-9.846h-9.846v-31.969c21.562,7.988,44.813,12.276,68.923,12.276c24.007,0,47.281-4.382,68.923-12.505v32.197h-9.846
                  l-9.846,9.846v39.385l9.846,9.846h39.385l9.846-9.846v-39.385l-9.846-9.846h-9.846V273.24
                  c33.92-17.164,62.604-44.022,81.981-77.74l16.481,208.177c0.328,3.775,0.388,7.46,0.233,11.062
                  c-0.718,11.425-3.531,21.951-8.24,31.072c-2.879,5.418-6.461,10.43-10.701,14.974c-4.362,4.362-9.308,8.135-14.697,11.237
                  c-0.353,0.192-0.699,0.392-1.057,0.578c-0.294,0.147-0.573,0.315-0.844,0.493c-9.984,5.341-21.421,8.369-33.617,8.369H142.754z
                   M186.077,333.769v19.692h-19.692v-19.692H186.077z M343.615,333.769v19.692h-19.692v-19.692H343.615z M491.308,424.354
                  c0,26.585-21.662,47.262-47.262,47.262h-3.938c6.582-7.816,11.783-16.84,15.472-26.775c4.131-10.821,6.374-22.605,6.453-34.896
                  c0.081-3.673-0.002-7.405-0.263-11.19l-1.969-25.6h13.785c9.846,0,17.723,7.877,17.723,17.723V424.354z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const IconSearch2 = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    fill="none"
    stroke={fill}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5"
  >
    <path d="m11.25 11.25 3 3" />
    <circle cx="7.5" cy="7.5" r="4.75" />
  </svg>
);
