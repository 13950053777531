import React from "react";

// Data statis
const staticProfile = {
  deskripsi:
    "Sekolah ini adalah institusi pendidikan yang memiliki visi untuk menciptakan generasi yang berkarakter dan kompeten di bidangnya. Kami berfokus pada pengembangan akademik dan non-akademik siswa untuk menghadapi tantangan global.",
  imageUrl: "https://via.placeholder.com/600x400.png?text=Foto+Sekolah",
  visiMisi: {
    visi: "Menjadi sekolah unggulan yang mencetak generasi berprestasi dan berbudi pekerti luhur.",
    misi: [
      "Menyediakan pendidikan berkualitas dengan fasilitas lengkap.",
      "Mengembangkan potensi siswa dalam bidang akademik dan non-akademik.",
      "Menanamkan nilai-nilai moral dan sosial kepada setiap siswa.",
    ],
  },
  jumlahSiswa: 1200,
  jumlahGuru: 75,
  jumlahStaff: 30,
  fasilitas: [
    "Laboratorium Komputer",
    "Perpustakaan Digital",
    "Lapangan Olahraga",
    "Ruang Musik",
    "Kantin Sehat",
  ],
  kontak: {
    email: "info@sekolah.com",
    telp: "021-12345678",
    alamat: "Jalan Pendidikan No. 10, Jakarta, Indonesia",
  },
};

// Komponen ProfileSekolah
const ProfileSekolah: React.FC = () => {
  const {
    deskripsi,
    imageUrl,
    visiMisi,
    jumlahSiswa,
    jumlahGuru,
    jumlahStaff,
    fasilitas,
    kontak,
  } = staticProfile;

  return (
    <div className="max-w-4xl font-inter mx-auto  py-5  space-y-12">
      {/* Gambar Sekolah dan Info */}
      <div className="flex flex-col md:flex-row  bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="flex gap-[20px]">
          <div className="w-[80px] h-[80px]">
            <img
              src={imageUrl}
              alt="Foto Sekolah"
              className="w-full h-full rounded-lg object-cover"
            />
          </div>
          <div className="text-[16px]">
            <p className="font-bold">Kepala Sekolah</p>
            <p className="font-semibold">Abdul Majid S.kom</p>
            <p>NIP : 289876786</p>
          </div>
        </div>
        <div className="py-4">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Profil Sekolah
          </h2>
          <p className="text-gray-600 indent-5">{deskripsi}</p>
        </div>
      </div>

      {/* Visi dan Misi */}
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Visi dan Misi</h2>
        <div className="mb-4">
          <h3 className="text-lg font-semibold text-gray-700">Visi</h3>
          <p className="text-gray-600">{visiMisi.visi}</p>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-700">Misi</h3>
          <ul className="list-disc list-inside text-gray-600 space-y-2">
            {visiMisi.misi.map((misiItem, index) => (
              <li key={index}>{misiItem}</li>
            ))}
          </ul>
        </div>
      </div>

      {/* Statistik Siswa, Guru, Staff */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <h3 className="text-xl font-bold text-gray-800">Jumlah Siswa</h3>
          <p className="text-4xl font-semibold text-blue-600 mt-2">
            {jumlahSiswa}
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <h3 className="text-xl font-bold text-gray-800">Jumlah Guru</h3>
          <p className="text-4xl font-semibold text-green-600 mt-2">
            {jumlahGuru}
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <h3 className="text-xl font-bold text-gray-800">Jumlah Staff</h3>
          <p className="text-4xl font-semibold text-yellow-600 mt-2">
            {jumlahStaff}
          </p>
        </div>
      </div>

      {/* Fasilitas */}
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Fasilitas</h2>
        <ul className="list-disc list-inside text-gray-600 space-y-2">
          {fasilitas.map((fasilitasItem, index) => (
            <li key={index}>{fasilitasItem}</li>
          ))}
        </ul>
      </div>

      {/* Kontak */}
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Kontak</h2>
        <p className="text-gray-600">
          <strong>Email:</strong> {kontak.email}
        </p>
        <p className="text-gray-600">
          <strong>Telepon:</strong> {kontak.telp}
        </p>
        <p className="text-gray-600">
          <strong>Alamat:</strong> {kontak.alamat}
        </p>
      </div>
    </div>
  );
};

export default ProfileSekolah;
