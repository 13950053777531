import React, { useState } from "react";

interface Experience {
  companyName: string;
  position: string;
  startDate: string;
  endDate: string;
  description: string;
}

const experienceData: Experience[] = [
  {
    companyName: "PT. Teknologi Canggih",
    position: "Software Engineer",
    startDate: "2022",
    endDate: "2024",
    description:
      "Bertanggung jawab untuk mengembangkan aplikasi web, termasuk fitur-fitur baru, perbaikan bug, dan optimasi performa. Selain itu, saya juga terlibat dalam perancangan arsitektur sistem dan mentoring tim junior untuk meningkatkan keterampilan mereka dalam pengembangan perangkat lunak.",
  },
  {
    companyName: "StartUp Kreatif",
    position: "Frontend Developer",
    startDate: "2021",
    endDate: "2022",
    description:
      "Mengembangkan dan memelihara antarmuka pengguna aplikasi berbasis web dengan menggunakan React dan TailwindCSS. Mengkoordinasikan dengan tim desain untuk memastikan pengalaman pengguna yang konsisten dan responsif di berbagai perangkat.",
  },
  {
    companyName: "Freelance",
    position: "Web Developer",
    startDate: "2020",
    endDate: "2021",
    description:
      "Mengerjakan berbagai proyek pengembangan web untuk klien, termasuk pembuatan situs web statis dan dinamis. Mengelola proyek dari awal hingga akhir, termasuk analisis kebutuhan klien, perancangan, dan pengujian.",
  },
];

const PengalamanProfile: React.FC = () => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleToggleExpand = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="p-6 bg-white shadow rounded-lg">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        Pengalaman Kerja
      </h2>

      <div className="space-y-6">
        {experienceData.map((exp, index) => (
          <div key={index} className="border-b border-gray-200 pb-4">
            <p className="text-lg font-medium text-gray-700">
              {exp.companyName}
            </p>
            <p className="text-sm text-gray-500">{exp.position}</p>
            <p className="text-sm text-gray-400">
              {exp.startDate} - {exp.endDate}
            </p>
            <p className="text-sm text-gray-600 mt-2">
              {expandedIndex === index
                ? exp.description
                : exp.description.length > 100
                ? `${exp.description.substring(0, 100)}...`
                : exp.description}
              <span className="pl-2">
                {exp.description.length > 100 && (
                  <span
                    className="text-blue-600  cursor-pointer inline-block"
                    onClick={() => handleToggleExpand(index)}
                  >
                    {expandedIndex === index ? "lebih sedikit" : "Selengkapnya"}
                  </span>
                )}
              </span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PengalamanProfile;
