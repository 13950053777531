// src/pages/NotificationPage.tsx

import React from "react";
import { IconSearch } from "../../Components/Navbar/Icons";

// Tipe data notifikasi dengan gambar, nama, deskripsi, dan tanggal
interface Notification {
  id: number;
  image: string;
  name: string;
  description: string;
  date: string;
}

const notifications: Notification[] = [
  {
    id: 1,
    image: "https://via.placeholder.com/50",
    name: "Gerlan",
    description:
      "Post Anda mendapatkan like baru! Post Anda mendapatkan like baru! Post Anda mendapatkan like baru!",
    date: "Baru diposting",
  },
  {
    id: 2,
    image: "https://via.placeholder.com/50",
    name: "Yaqin",
    description: "Post Anda mendapatkan komentar baru.",
    date: "4 jam yang lalu",
  },
  {
    id: 3,
    image: "https://via.placeholder.com/50",
    name: "Nani",
    description: "Lowongan magang baru telah diposting.",
    date: "1 hari yang lalu",
  },
  {
    id: 4,
    image: "https://via.placeholder.com/50",
    name: "Sarah",
    description: "Event kegiatan sekolah baru di-update.",
    date: "2 hari yang lalu",
  },
  {
    id: 5,
    image: "https://via.placeholder.com/50",
    name: "Dahlan",
    description: "Tugas baru telah diberikan oleh guru.",
    date: "3 hari yang lalu",
  },
  {
    id: 5,
    image: "https://via.placeholder.com/50",
    name: "Dahlan",
    description: "Tugas baru telah diberikan oleh guru.",
    date: "3 hari yang lalu",
  },
  {
    id: 5,
    image: "https://via.placeholder.com/50",
    name: "Dahlan",
    description: "Tugas baru telah diberikan oleh guru.",
    date: "3 hari yang lalu",
  },
  {
    id: 5,
    image: "https://via.placeholder.com/50",
    name: "Dahlan",
    description: "Tugas baru telah diberikan oleh guru.",
    date: "3 hari yang lalu",
  },
  {
    id: 5,
    image: "https://via.placeholder.com/50",
    name: "Dahlan",
    description: "Tugas baru telah diberikan oleh guru.",
    date: "3 hari yang lalu",
  },
  {
    id: 5,
    image: "https://via.placeholder.com/50",
    name: "Dahlan",
    description: "Tugas baru telah diberikan oleh guru.",
    date: "3 hari yang lalu",
  },
  {
    id: 5,
    image: "https://via.placeholder.com/50",
    name: "Dahlan",
    description: "Tugas baru telah diberikan oleh guru.",
    date: "3 hari yang lalu",
  },
];

const NotificationPage: React.FC = () => {
  return (
    <div className="container font-inter mx-auto ">
      <div className="fixed border-b shadow lg:w-[770px] border-slate-300 px-4 lg:mt-[-15px] py-2 flex justify-between bg-white w-full">
        <h1 className="text-[18px] font-bold   text-gray-800">Pemberitahuan</h1>
        <div>{IconSearch("24", "24", "gray")}</div>
      </div>
      <div className="space-y-2 px-4 pt-[30px] lg:pt-[40px]">
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <div
              key={notification.id}
              className="flex items-center p-4 border border-gray-200 rounded-lg shadow-sm bg-white hover:bg-gray-50 transition duration-300"
            >
              <img
                src={notification.image}
                alt={notification.name}
                className="w-12 h-12 rounded-full border border-gray-300 mr-4"
              />
              <div className="flex-1">
                <p className="text-gray-700 text-[12px] mb-1">
                  <span className="text-blue-600 pr-1">
                    {notification.name}
                  </span>
                  {notification.description}
                </p>
                <p className="text-[10px] text-right w-full text-gray-500">
                  {notification.date}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-center">Belum ada notifikasi.</p>
        )}
      </div>
    </div>
  );
};

export default NotificationPage;
