
import { RouterProvider } from 'react-router-dom';
import { Routers } from './Routes';

function App() {
  return (
    <div >
      <RouterProvider router={Routers} />
    </div>
  );
}

export default App;
