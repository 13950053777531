import React, { useState } from "react";
import {
  IconWork,
  IconMessages,
  IconSearch,
  IconNotification,
  IconHome,
} from "./Icons";
import { Link, useLocation } from "react-router-dom";
import MessagesPage from "../../Pages/MessagesPage";

const Navbar: React.FC = () => {
  const location = useLocation();
  const [showMessages, setShowMessages] = useState(false);

  const handleOpenMessages = () => {
    setShowMessages(true);
  };

  const handleCloseMessages = () => {
    setShowMessages(false);
  };
  return (
    <section className="border  lg:w-[900px]   lg:border-none bg-white lg:bg-transparent py-[10px] rounded-t-xl">
      <div className="grid relative grid-cols-5 lg:flex lg:gap-5 lg:ml-[600px] justify-center items-center mx-[20px] lg:mx-[10px] gap-[10px] ">
        <Link to={"/"}>
          {/* Beranda */}
          <div className="flex justify-center">
            {/* Tampilkan ikon di tampilan kecil, sembunyikan di lg */}
            <div className="lg:hidden">
              {IconHome(
                location.pathname === "/" ? "40" : "35",
                location.pathname === "/" ? "40" : "35",
                location.pathname === "/" ? "blue" : "gray"
              )}
            </div>
            <div className="lg:block hidden">
              {IconHome(
                location.pathname === "/" ? "30" : "30",
                location.pathname === "/" ? "30" : "30",
                location.pathname === "/" ? "red" : "white"
              )}
            </div>
          </div>
        </Link>
        <Link to={"/messages"}>
          {/* Pesan */}
          <div className="lg:hidden flex justify-center">
            <div className="lg:hidden">
              {IconMessages(
                location.pathname === "/messages" ? "40" : "35",
                location.pathname === "/messages" ? "40" : "35",
                location.pathname === "/messages" ? "blue" : "gray"
              )}
            </div>
          </div>
        </Link>
        <Link to={"/search"}>
          {/* Pencarian */}
          <div className="flex justify-center">
            <div className="lg:hidden">
              {IconSearch(
                location.pathname === "/search" ? "40" : "35",
                location.pathname === "/search" ? "40" : "35",
                location.pathname === "/search" ? "blue" : "gray"
              )}
            </div>
            <span className="hidden absolute ml-[-800px] mt-[-20px] lg:block text-sm font-medium lg:text-lg  lg:text-white">
              <div className="flex gap-2">
                <input
                  type="text"
                  placeholder="Pencarian..."
                  className="w-[500px] text-[14px] px-[20px] py-[2px] rounded-full "
                />
                {IconSearch("30", "30", "white")}
              </div>
            </span>
          </div>
        </Link>
        <Link to={"/notification"}>
          {/* Pemberitahuan */}
          <div className="flex justify-center">
            <div className="lg:hidden">
              {IconNotification(
                location.pathname === "/notification" ? "40" : "35",
                location.pathname === "/notification" ? "40" : "35",
                location.pathname === "/notification" ? "blue" : "gray"
              )}
            </div>
            <div className="lg:block hidden ">
              {IconNotification(
                location.pathname === "/notification" ? "30" : "30",
                location.pathname === "/notification" ? "30" : "30",
                location.pathname === "/notification" ? "red" : "white"
              )}
            </div>
          </div>
        </Link>
        <Link to={"/internship"}>
          {/* Magang */}
          <div className="flex justify-center">
            <div className="lg:hidden">
              {IconWork(
                location.pathname === "/internship" ? "40" : "35",
                location.pathname === "/internship" ? "40" : "35",
                location.pathname === "/internship" ? "blue" : "gray"
              )}
            </div>
            <div className="lg:block hidden">
              {IconWork(
                location.pathname === "/internship" ? "30" : "30",
                location.pathname === "/internship" ? "30" : "30",
                location.pathname === "/internship" ? "red" : "white"
              )}
            </div>
          </div>
        </Link>
        <div
          onClick={handleOpenMessages}
          className="hidden lg:block lg:right-[40px] lg:rounded-full lg:items-center  lg:p-[10px] lg:bg-white lg:shadow-lg lg:fixed lg:bottom-2 text-sm font-medium lg:text-lg  "
        >
          {IconMessages("40", "40", "gray")}
        </div>
        <div className="absolute mt-[700px] right-0 mr-[-150px]">
          {showMessages && <MessagesPage onClose={handleCloseMessages} />}
        </div>
      </div>
    </section>
  );
};

export default Navbar;
