import React from "react";

const Following = () => {
  return (
    <div className="w-[300px] font-inter bg-white">
      <div className=" h-full border border-slate-300 rounded-lg">
        <p className="  px-2 py-[5px]">Disarankan</p>
        <div className="flex flex-col gap-[10px] my-[10px] px-[10px] overflow-y-auto h-[300px]">
          {[1, 2, 3, 4, 5, 6, 7].map((i) => (
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <div className="w-[40px] h-[40px]">
                  <img
                    src="https://picsum.photos/200/300?random=4"
                    alt=""
                    className="w-full h-full object-cover rounded-full"
                  />
                </div>
                <p className="font-roboto text-[12px] font-bold">
                  SMAN 1 Pedes Karawang
                </p>
              </div>
              <button className="border border-blue-800 rounded-lg py-[2px] px-[15px] text-[10px]">
                Ikuti
              </button>
            </div>
          ))}
          {[1, 2, 3, 4, 5, 6, 7].map((i) => (
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <div className="w-[40px] h-[40px]">
                  <img
                    src="https://picsum.photos/200/300?random=4"
                    alt=""
                    className="w-full h-full object-cover rounded-full"
                  />
                </div>
                <div>
                  <p className="font-roboto text-[12px] font-bold">
                    SMAN 1 Pedes Karawang
                  </p>
                  <p className="font-roboto text-[12px] text-gray-800">
                    Abdul Majid
                  </p>
                </div>
              </div>
              <button className="border border-blue-800 rounded-lg py-[2px] px-[15px] text-[10px]">
                Ikuti
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Following;
