import React from "react";
import Slider from "react-slick";

interface ProgramDetail {
  title: string;
  descriptions: {
    paragraph: string;
  }[];
  schedule: string;
  contact: string;
  images: string[];
  achievements: string[];
}

const programDetail: ProgramDetail = {
  title: "Program Teknologi dan Robotika",
  descriptions: [
    {
      paragraph:
        "Program ini mengajarkan dasar-dasar teknologi dan robotika. Siswa akan belajar tentang pemrograman, elektronik, dan pengembangan robot.",
    },
    {
      paragraph:
        "Materi meliputi pembuatan dan pemrograman robot, penggunaan sensor, serta teknik-teknik dasar dalam teknologi modern.",
    },
    {
      paragraph:
        "Siswa juga akan berpartisipasi dalam proyek kelompok dan kompetisi yang menguji keterampilan yang telah dipelajari.",
    },
  ],
  schedule: "Senin dan Rabu, 15:00 - 17:00",
  contact: "email@programrobotika.com",
  images: [
    "https://picsum.photos/200/300?random=1",
    "https://picsum.photos/200/300?random=2",
    "https://picsum.photos/200/300?random=3",
  ],
  achievements: [
    "Juara 1 Kompetisi Robotika Nasional 2023",
    "Best Project di Expo Teknologi 2022",
    "Penghargaan Inovasi Teknologi 2021",
  ],
};

const DetailProgramSchool: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="max-w-4xl mx-auto p-4 font-inter">
      <div className="flex mb-[20px] gap-[20px] items-center">
        <div className="w-[60px] h-[60px]">
          <img
            src="https://picsum.photos/200/300?random=5"
            alt=""
            className="w-full h-full object-cover rounded-full"
          />
        </div>
        <p className="text-[18px] font-roboto font-bold text-gray-500">
          SMAN 1 Pedes Karawang
        </p>
      </div>

      {/* Image Slider */}
      <div className="mb-8">
        <Slider {...settings}>
          {programDetail.images.map((image, index) => (
            <div key={index} className="relative group">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-64 object-cover rounded-lg shadow-lg transition-transform duration-500 ease-in-out transform group-hover:scale-105"
              />
            </div>
          ))}
        </Slider>
      </div>
      <h1 className="text-[20px] font-bold text-gray-800 mb-2">
        {programDetail.title}
      </h1>

      <div className="p-2">
        {/* Descriptions */}
        <div className="mb-4">
          {programDetail.descriptions.map((desc, index) => (
            <p key={index} className="text-gray-700 text-lg indent-5 mb-4">
              {desc.paragraph}
            </p>
          ))}
        </div>

        <div className="mb-4">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">Jadwal:</h2>
          <p className="text-gray-600">{programDetail.schedule}</p>
        </div>

        {/* Achievements */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Prestasi:
          </h2>
          <ul className="list-disc list-inside text-gray-600 space-y-2">
            {programDetail.achievements.map((achievement, index) => (
              <li
                key={index}
                className="transition-transform duration-300 ease-in-out transform hover:scale-105 hover:text-gray-800"
              >
                {achievement}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DetailProgramSchool;
