import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import SidebarTeacher from "../../Components/sidebarTeacher";
import Following from "../../Components/following";

const Dashboard: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const location = useLocation();

  const closeMenus = () => {
    setIsSidebarOpen(false);
  };

  const isNavbarVisible = location.pathname !== "/ujian";
  const isSidebarVisible =
    !location.pathname.includes("/siswa") &&
    !location.pathname.includes("/guru");

  return (
    <div className="max-w-[1440px] mx-auto min-h-screen flex flex-col">
      <div className="fixed w-full z-40 lg:z-30">
        <Sidebar isOpen={isSidebarOpen} closeMenu={closeMenus} />
        {/* <SidebarTeacher isOpen={isSidebarOpen} closeMenu={closeMenus} /> */}
      </div>

      <div className="flex flex-row-reverse relative gap-8 lg:mt-[70px]">
        <div
          className="flex-grow lg:ml-[21%] w-full lg:mr-[23%] my-[58px]  lg:my-0"
          onClick={closeMenus}
        >
          <Outlet />
        </div>
        {isSidebarVisible && (
          <div className="hidden  lg:block mt-[3%] top-[10%] fixed">
            <Following />
          </div>
        )}
      </div>

      {isNavbarVisible && (
        <div className="fixed lg:ml-[300px] w-full lg:w-auto lg:h-[40px] bottom-0 lg:top-[10px] z-50 lg:z-80 font-inter">
          <Navbar />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
