import React, { useState } from "react";
import { IconLike, IconUser } from "../../../Assets/Icons";

interface Comment {
  id: number;
  content: string;
  userName: string;
  timeAgo: string;
  replies: Comment[];
}

interface CommentPostProps {
  comments: Comment[];
  onClose: () => void;
}

const CommentPost: React.FC<CommentPostProps> = ({ comments, onClose }) => {
  const [replyingTo, setReplyingTo] = useState<number | null>(null);
  const [likedComments, setLikedComments] = useState<number[]>([]);
  const [newComment, setNewComment] = useState("");
  const [newReply, setNewReply] = useState("");

  const handleReplyClick = (id: number) => {
    setReplyingTo(replyingTo === id ? null : id);
  };

  const handleLikeClick = (id: number) => {
    if (likedComments.includes(id)) {
      setLikedComments(likedComments.filter((commentId) => commentId !== id));
    } else {
      setLikedComments([...likedComments, id]);
    }
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewComment(e.target.value);
  };

  const handleReplyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewReply(e.target.value);
  };

  const handlePostComment = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle posting of the new comment
    setNewComment("");
  };

  const handlePostReply = (e: React.FormEvent, commentId: number) => {
    e.preventDefault();

    setNewReply("");
    setReplyingTo(null); // Close the reply input after posting
  };

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white pb-[150px] rounded-lg w-full h-full fixed">
        <div className="flex justify-between w-full fixed bg-slate-300 p-4">
          <h3 className="text-lg font-semibold mb-2">Comments</h3>
          <button className="text-gray-500" onClick={onClose}>
            Close
          </button>
        </div>
        <div className="overflow-y-auto h-full p-4 mt-[70px]">
          {comments.length > 0 ? (
            <ul>
              {comments.map((comment) => (
                <li key={comment.id} className="mb-4 border-b pb-2">
                  <div className="flex gap-2 items-center">
                    <div className="w-[60px] h-[60px]">
                      <img
                        src="https://picsum.photos/200/300?random=8"
                        alt=""
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                    <p className="font-semibold">{comment.userName}</p>
                  </div>
                  <p className="text-sm text-gray-600 pl-[37px]">
                    {comment.content}
                  </p>
                  <div className="flex justify-between pl-[37px] mt-[5px]">
                    <p className="text-xs text-gray-400">{comment.timeAgo}</p>
                    <div className="flex gap-3">
                      <button
                        className="text-gray-500 flex gap-2"
                        onClick={() => handleReplyClick(comment.id)}
                      >
                        <p>Balas</p> <p>20</p>
                      </button>
                      <button
                        className="text-gray-500 ml-2 flex gap-2"
                        onClick={() => handleLikeClick(comment.id)}
                      >
                        {IconLike(
                          "20",
                          "20",
                          likedComments.includes(comment.id) ? "blue" : "gray"
                        )}
                        <p>20</p>
                      </button>
                    </div>
                  </div>

                  {/* Display replies if replyingTo is the current comment id */}
                  {replyingTo === comment.id && comment.replies.length > 0 && (
                    <ul className="pl-8 mt-4">
                      {comment.replies.map((reply) => (
                        <li key={reply.id} className="mb-2 border-b pb-2">
                          <div className="flex gap-2">
                            <div className="w-[60px] h-[60px]">
                              <img
                                src="https://picsum.photos/200/300?random=8"
                                alt=""
                                className="w-full h-full object-cover rounded-full"
                              />
                            </div>
                            <p className="font-semibold">{reply.userName}</p>
                          </div>
                          <p className="text-sm text-gray-600 pl-[37px]">
                            {reply.content}
                          </p>
                          <p className="text-xs text-gray-400 pl-[37px]">
                            {reply.timeAgo}
                          </p>
                        </li>
                      ))}
                    </ul>
                  )}

                  {/* Display reply input field if replyingTo is the current comment id */}
                  {replyingTo === comment.id && (
                    <form
                      className="flex gap-4 mt-4 pl-[37px]"
                      onSubmit={(e) => handlePostReply(e, comment.id)}
                    >
                      <div className="w-[60px] h-[60px]">
                        <img
                          src="https://picsum.photos/200/300?random=8"
                          alt=""
                          className="w-full h-full object-cover rounded-full"
                        />
                      </div>
                      <input
                        type="text"
                        value={newReply}
                        onChange={handleReplyChange}
                        className="w-full px-3 py-2 rounded-md outline-none border border-slate-100"
                        placeholder="Balas Komentar"
                      />
                      <button className="text-white bg-blue-500 px-3 py-2 rounded-md">
                        Post
                      </button>
                    </form>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p>No comments available</p>
          )}
        </div>
        <div className="px-[15px]">
          <form className="flex gap-4 mt-4" onSubmit={handlePostComment}>
            <div className="w-[60px] h-[60px]">
              <img
                src="https://picsum.photos/200/300?random=8"
                alt=""
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <input
              type="text"
              value={newComment}
              onChange={handleCommentChange}
              className="w-full px-3 py-2 rounded-md outline-none border border-slate-100"
              placeholder="Buat Komentar..."
            />
            <button className="text-white bg-blue-500 px-3 py-2 rounded-md">
              Post
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CommentPost;
