import React from "react";
import { Link } from "react-router-dom";

const RoleSection: React.FC = () => {
  const roles = [
    { path: "/siswa", label: "Siswa" },
    { path: "/guru", label: "Guru" },
    { path: "/orang-tua", label: "Orang Tua" },
    { path: "/sekolah", label: "Sekolah" },
  ];

  return (
    <div className="flex bg-red-900 justify-center items-center h-screen">
      <div className="grid grid-cols-2 gap-4">
        {roles.map((role) => (
          <Link to={role.path} key={role.label}>
            <div className="bg-blue-500 text-white p-6 rounded-lg shadow-lg hover:bg-blue-600 transition-colors">
              <button className="text-lg font-semibold">{role.label}</button>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RoleSection;
