import React, { useState } from "react";
import { ImageGoogle } from "../../Assets/Images";
import { IconEye, IconEyesClose } from "../../Assets/Icons";

const FormLogin: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State untuk mengontrol visibility password
  const [error, setError] = useState("");

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();

    // Contoh data statis untuk validasi login
    const staticUser = {
      email: "user@example.com",
      password: "password123",
    };

    if (email === staticUser.email && password === staticUser.password) {
      setError("");
      alert("Login successful!");
    } else {
      setError("Invalid email or password.");
    }
  };

  return (
    <div className="relative font-inter h-screen w-full flex items-center justify-center bg-gray-100">
      {/* Background Video */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="https://www.w3schools.com/howto/rain.mp4"
        autoPlay
        loop
        muted
      />

      {/* Overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>

      {/* Login Form */}
      <div className="relative z-10 bg-white p-8 mx-6 rounded-md shadow-md max-w-md w-full items-center">
        <h2 className="text-3xl font-bold mb-6 text-center">Login</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleLogin} className="flex flex-col text-[14px]">
          <label className="mb-2 text-[14px] text-gray-700">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mb-4 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter your email"
            required
          />

          <label className="mb-2 text-gray-700">Password</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mb-4 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
              placeholder="Enter your password"
              required
            />
            <button
              type="button"
              className="absolute right-2 top-2 text-gray-600 hover:text-gray-800 focus:outline-none"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <IconEyesClose /> : <IconEye />}
            </button>
          </div>

          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-all duration-200"
          >
            Login
          </button>
        </form>
        <p className="text-right text-blue-500 mt-4 cursor-pointer">
          Lupa Password?
        </p>

        <div className="flex flex-col items-center mt-6">
          <hr className="w-full border-gray-300 mb-4" />
          <p className="text-gray-500 mb-4">Atau</p>
          <div className="h-10 w-10">
            <img
              src={ImageGoogle}
              alt="Google Icon"
              className="w-full h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormLogin;
