import React from "react";
import { Link } from "react-router-dom";

interface EventDetail {
  id: number;
  title: string;
  date: string;
  location: string;
  description: {
    paragraf: string;
  }[];
  imageUrl: string;
  organizer: string;
  time: string;
  contactEmail: string;
  contactPhone: string;
  ticketPrice: string;
  benefits: {
    paragraf: string;
  }[];
}

const eventDetail: EventDetail = {
  id: 1,
  title: "Konferensi Teknologi 2024",
  date: "10 Maret 2024",
  location: "Jakarta Convention Center",
  description: [
    {
      paragraf:
        "Selamat datang di Konferensi Teknologi 2024, acara terbesar dan paling dinanti di industri teknologi! Bergabunglah dengan kami untuk hari penuh inspirasi, inovasi, dan jaringan yang tak tertandingi.",
    },
    {
      paragraf:
        "Di acara ini, Anda akan bertemu dengan para pemimpin dan pelopor industri, belajar dari sesi keynote yang mendalam, dan berpartisipasi dalam diskusi panel yang memicu ide-ide baru. Jangan lewatkan kesempatan untuk mengikuti lokakarya yang dirancang untuk memberikan wawasan praktis dan keterampilan yang dapat langsung diterapkan di lapangan.",
    },
    {
      paragraf:
        "Apakah Anda seorang profesional berpengalaman atau baru memulai perjalanan teknologi Anda, Konferensi Teknologi 2024 menawarkan pengalaman yang berharga dan peluang untuk memperluas jaringan Anda secara signifikan.",
    },
  ],
  imageUrl: "https://picsum.photos/200/300?random=2",
  organizer: "Super Digital Apps",
  time: "09:00 AM - 05:00 PM",
  contactEmail: "info@konferensiteknologi2024.com",
  contactPhone: "+62 812 3456 7890",
  ticketPrice: "IDR 500,000",
  benefits: [
    {
      paragraf: "Kesempatan berjejaring dengan pemimpin dan ahli industri.",
    },
    {
      paragraf: "Akses eksklusif ke pidato utama dan diskusi panel.",
    },
    {
      paragraf: "Lokakarya praktis dengan aplikasi nyata.",
    },
    {
      paragraf: "Materi acara dan barang gratis.",
    },
    {
      paragraf: "Sertifikat partisipasi untuk peserta.",
    },
  ],
};

const DetailEvent: React.FC = () => {
  return (
    <div className="container font-inter mx-auto p-4">
      <div className="flex  gap-[10px] mb-[10px] items-center">
        <div className="w-[60px] h-[60px] ">
          <img
            src="https://picsum.photos/200/300?random=2"
            alt=""
            className="w-full h-full rounded-full object-cover"
          />
        </div>
        <p className="text-[16px] text-gray-800">SMAN 1 Pedes karawang</p>
      </div>
      <div className="flex flex-col  bg-white shadow-lg rounded-lg overflow-hidden">
        {/* Bagian Gambar */}
        <div className="">
          <img
            className="w-full h-64 object-cover"
            src={eventDetail.imageUrl}
            alt={eventDetail.title}
          />
        </div>

        {/* Bagian Detail Event */}
        <div className=" p-2">
          <h1 className="text-[20px] font-bold text-gray-900 mb-2">
            {eventDetail.title}
          </h1>
          <p className="text-sm text-gray-500 mb-2">
            {eventDetail.date} | {eventDetail.time} | {eventDetail.location}
          </p>

          <div className="mt-4">
            {eventDetail.description.map((desc, index) => (
              <p
                key={index}
                className="text-gray-700 mb-2 text-[13px] indent-8"
              >
                {desc.paragraf}
              </p>
            ))}
          </div>

          <div className="mt-6">
            <h2 className="text-lg font-semibold text-gray-800">
              Diselenggarakan oleh
            </h2>
            <p className="text-gray-600 text-[13px]">{eventDetail.organizer}</p>

            <h2 className="text-lg font-semibold text-gray-800 mt-4">
              Informasi Kontak
            </h2>
            <p className="text-gray-600">
              Email:{" "}
              <a
                href={`mailto:${eventDetail.contactEmail}`}
                className="text-blue-600 text-[13px]"
              >
                {eventDetail.contactEmail}
              </a>
            </p>
            <p className="text-gray-600">
              Telepon:{" "}
              <a
                href={`tel:${eventDetail.contactPhone}`}
                className="text-blue-600 text-[13px]"
              >
                {eventDetail.contactPhone}
              </a>
            </p>

            <h2 className="text-lg font-semibold text-gray-800 mt-4">
              Harga Tiket
            </h2>
            <p className="text-gray-600 text-[13px]">
              {eventDetail.ticketPrice}
            </p>

            <h2 className="text-lg font-semibold text-gray-800 mt-4">
              Manfaat untuk Peserta
            </h2>
            <ul className="list-disc pl-5 text-gray-700">
              {eventDetail.benefits.map((benefit, index) => (
                <li key={index} className="mb-2 text-[13px]">
                  {benefit.paragraf}
                </li>
              ))}
            </ul>
          </div>

          {/* Tombol Daftar */}
          <Link to={"/register-event"}>
            <button className="mt-6 bg-blue-600 text-white font-semibold py-2 px-4 rounded hover:bg-blue-700 transition">
              Daftar Sekarang
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DetailEvent;
