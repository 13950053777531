import React from "react";
import { Link } from "react-router-dom";

interface OfficeDetails {
  name: string;
  address: string;
  website: string;
  followers: number;
  image: string;
  description: string;
}

interface JobPosting {
  id: string;
  title: string;
  company: string;
  location: string;
  description: string;
  requirements: string[];
  benefits: string[];
  feedback: string[];
  image: string;
  postedDate: string;
  office: OfficeDetails;
}

const jobPosting: JobPosting = {
  id: "1",
  title: "Frontend Developer Intern",
  company: "Tech Company A",
  location: "Jakarta, Indonesia",
  description:
    "Join our innovative team as a Frontend Developer Intern. Work with cutting-edge technologies and contribute to impactful projects that shape the future of web development.",
  requirements: [
    "Experience with React.js or similar frameworks",
    "Familiarity with HTML, CSS, and JavaScript",
    "Strong problem-solving skills and eagerness to learn",
  ],
  benefits: [
    "Hands-on experience with the latest web technologies",
    "Opportunity to work in a collaborative and dynamic team environment",
    "Mentorship from industry-leading developers and professionals",
  ],
  feedback: [
    "An incredible place to learn and grow your skills",
    "Supportive and friendly team atmosphere",
    "Excellent opportunities for career development and networking",
  ],
  image: "https://via.placeholder.com/800x400",
  postedDate: "2 jam yang lalu",
  office: {
    name: "Tech Company A",
    address: "Jl. Merdeka No. 123, Jakarta, Indonesia",
    website: "https://www.techcompanya.com",
    followers: 1200,
    image: "https://via.placeholder.com/400x200",
    description:
      "Tech Company A is a leader in technology, known for its innovative solutions and cutting-edge web applications. We are dedicated to driving technological advancements and creating a vibrant, inclusive work environment.",
  },
};

const DetailWork: React.FC = () => {
  return (
    <div className="container mt-[20px] mx-auto p-4 bg-gray-50">
      <div className="flex flex-col lg:flex-row lg:space-x-8">
        {/* Job Details Section */}
        <div className="lg:w-2/3 bg-white shadow-xl rounded-lg p-4 mb-6 lg:mb-0 border border-gray-300">
          <img
            src={jobPosting.image}
            alt={jobPosting.title}
            className="w-full h-64 object-cover rounded-lg mb-6 border border-gray-300"
          />
          <h1 className="text-[22px] font-bold text-blue-800 mb-4">
            {jobPosting.title}
          </h1>
          <p className="text-[18px] font-semibold text-gray-700 mb-2">
            {jobPosting.company}
          </p>
          <p className="text-gray-600 mb-2">{jobPosting.location}</p>
          <p className="text-gray-400 text-sm mb-6">{jobPosting.postedDate}</p>
          <h2 className="text-[18px] font-semibold text-blue-700 mb-1">
            Job Description
          </h2>
          <p className="text-[14px] font-inter text-gray-700 mb-6">
            {jobPosting.description}
          </p>
          <h2 className="text-[18px] font-semibold text-blue-700 mb-1">
            Requirements
          </h2>
          <ul className="list-disc list-inside mb-6 text-[14px] text-gray-700">
            {jobPosting.requirements.map((req, index) => (
              <li key={index} className="flex items-center mb-2">
                <svg
                  className="w-5 h-5 text-blue-500 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                {req}
              </li>
            ))}
          </ul>
          <h2 className="text-[18px] font-semibold text-blue-700 mb-1">
            Benefits of Joining
          </h2>
          <ul className="list-disc list-inside mb-6 text-[14px] text-gray-700">
            {jobPosting.benefits.map((benefit, index) => (
              <li key={index} className="flex items-center mb-2">
                <svg
                  className="w-5 h-5 text-blue-500 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                {benefit}
              </li>
            ))}
          </ul>
          <h2 className="text-[18px] font-semibold text-blue-700 mb-1">
            Feedback from Past Interns
          </h2>
          <ul className="list-disc list-inside mb-6  text-[14px] text-gray-700">
            {jobPosting.feedback.map((item, index) => (
              <li key={index} className="flex items-center mb-2">
                <svg
                  className="w-5 h-5 text-blue-500 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                {item}
              </li>
            ))}
          </ul>
          <div className="mt-8 flex justify-center">
            <Link to={"/internship/form"}>
              <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded-full shadow-lg transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-blue-300">
                Melamar Sekarang
              </button>
            </Link>
          </div>
        </div>
        {/* Company Details Section */}
        <div className="lg:w-1/3 bg-white p-6 rounded-lg shadow-xl border border-gray-300">
          <div className="flex gap-[10px] items-center mb-6">
            <div className="w-[60px] h-[60px]">
              <img
                src={jobPosting.office.image}
                alt={jobPosting.office.name}
                className="w-full h-full object-cover rounded-full "
              />
            </div>
            <div>
              <h2 className="text-[18px] font-semibold text-blue-800 mb-1">
                {jobPosting.office.name}
              </h2>
              <p className="text-gray-600 text-[14px]  mb-2">
                {jobPosting.office.followers} Followers
              </p>
            </div>
          </div>
          <p className="text-gray-700 text-[12PX] mb-4">
            {jobPosting.office.description}
          </p>
          <p className="text-[14px] font-semibold mb-2">
            <strong>Address:</strong> {jobPosting.office.address}
          </p>

          <p className="text-[14px] mb-4">
            <strong>Website:</strong>{" "}
            <a
              href={jobPosting.office.website}
              className="text-blue-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {jobPosting.office.website}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DetailWork;
