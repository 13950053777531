import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IconChevron } from "../../Assets/Icons";

interface BannerItem {
  image: string;
  title: string;
  description: string;
}

const Slide: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef<Slider | null>(null);

  const banners: BannerItem[] = [
    {
      image: "https://picsum.photos/200/300?random=5",
      title: "Title 1",
      description:
        "This is the description for banner 1.This is the description for banner 1.This is the description for banner 1.This is the description for banner 1.This is the description for banner 1.This is the description for banner 1.",
    },
    {
      image: "https://picsum.photos/200/300?random=4",
      title: "Title 2",
      description:
        "This is the description for banner 1.This is the description for banner 1.This is the description for banner 1.This is the description for banner 1.This is the description for banner 1.This is the description for banner 1.",
    },
    {
      image: "https://picsum.photos/200/300?random=6",
      title: "Title 3",
      description:
        "This is the description for banner 1.This is the description for banner 1.This is the description for banner 1.This is the description for banner 1.This is the description for banner 1.This is the description for banner 1.",
    },
  ];

  const settings = {
    dots: false,
    infinite: banners.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: banners.length > 1,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: "5%",
    afterChange: (index: number) => setCurrentIndex(index),
    arrows: false,
    cssEase: "ease-in-out",
  };

  const nextSlide = () => {
    sliderRef.current?.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current?.slickPrev();
  };

  const goToSlide = (index: number) => {
    sliderRef.current?.slickGoTo(index);
    setCurrentIndex(index);
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  return (
    <main className="w-full relative ">
      <div className="absolute top-1/2 left-4 transform -translate-y-1/2 z-10">
        <button
          onClick={prevSlide}
          className="bg-[#EEF9FF] hidden rounded-full lg:h-[70px] lg:w-[70px] h-[40px] w-[40px] p-auto focus:outline-none"
          aria-label="Previous Slide"
        >
          <p className="lg:pl-[30px] pl-[10px] rotate-90 ml-[-3px]">
            {IconChevron("30", "30", "black")}
          </p>
        </button>
      </div>
      <div className="absolute top-1/2 right-4 transform -translate-y-1/2 z-10">
        <button
          onClick={nextSlide}
          className="bg-[#EEF9FF] hidden rounded-full lg:h-[70px] lg:w-[70px] h-[40px] w-[40px] p-auto focus:outline-none"
          aria-label="Next Slide"
        >
          <p className="lg:pl-[20px] -rotate-90 pl-[25px] ml-[8px]">
            {IconChevron("30", "30", "black")}
          </p>
        </button>
      </div>
      <Slider ref={sliderRef} {...settings} className=" h-[380px]">
        {banners.map((item, index) => (
          <div
            key={index}
            className={`w-full outline-none duration-500 ${
              index === currentIndex
                ? "h-[371px] rounded-lg"
                : "h-[320px] mx-auto rounded-lg"
            } relative`}
          >
            <img
              src={item.image}
              alt={`Banner Image ${index}`}
              className="w-full h-full object-cover rounded-lg"
            />
            <div className="absolute pb-[25px] bg-slate-500 bg-opacity-50 bottom-0 left-0 w-full bg-gradient-to-t from-black via-transparent to-transparent text-slate-100 p-4 rounded-b-lg">
              <h2 className="text-xl font-bold mb-2">{item.title}</h2>
              <p className="text-sm">{truncateText(item.description, 200)}</p>
            </div>
          </div>
        ))}
      </Slider>
      <div className="absolute bottom-4 left-0 right-0 flex justify-center ">
        {banners.length > 1 &&
          banners.map((_, index) => (
            <button
              key={index}
              className={`h-3 w-3 rounded-full mx-1 ${
                index === currentIndex
                  ? "bg-[#1069E5] w-[50px]"
                  : "bg-slate-400"
              }`}
              onClick={() => goToSlide(index)}
              aria-label={`Go to slide ${index + 1}`}
            ></button>
          ))}
      </div>
    </main>
  );
};

export default Slide;
