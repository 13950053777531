import React, { useState } from "react";
import TruncateText from "../../../Components/truncateText";

interface BorrowStatus {
  id: number;
  title: string;
  desc: string;
  status:
    | "pengajuan"
    | "dibatalkan"
    | "dipinjam"
    | "denda"
    | "dikembalikan"
    | "dipesan";
}

const StatusBorrow: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<
    | "dipesan"
    | "dibatalkan"
    | "pengajuan"
    | "dipinjam"
    | "denda"
    | "dikembalikan"
  >("dipesan");

  // State to keep track of checked items
  const [checkedItems, setCheckedItems] = useState<Set<number>>(new Set());

  // Data dummy untuk status pinjaman buku
  const borrowData: BorrowStatus[] = [
    {
      id: 1,
      title: "Buku A",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos ut doloribus eos excepturi quasi ipsum voluptatibus amet consequatur quibusdam accusantium at ad dolore quam tempore quisquam quo nulla mollitia beatae, voluptas nobis eveniet asperiores neque commodi numquam. Soluta ab quo corporis quod at consequuntur veritatis, fuga a minima alias ipsum",
      status: "pengajuan",
    },
    {
      id: 2,
      title: "Buku B",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos ut doloribus eos excepturi quasi ipsum voluptatibus amet consequatur quibusdam accusantium at ad dolore quam tempore quisquam quo nulla mollitia beatae, voluptas nobis eveniet asperiores neque commodi numquam. Soluta ab quo corporis quod at consequuntur veritatis, fuga a minima alias ipsum",
      status: "dipinjam",
    },
    {
      id: 3,
      title: "Buku C",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos ut doloribus eos excepturi quasi ipsum voluptatibus amet consequatur quibusdam accusantium at ad dolore quam tempore quisquam quo nulla mollitia beatae, voluptas nobis eveniet asperiores neque commodi numquam. Soluta ab quo corporis quod at consequuntur veritatis, fuga a minima alias ipsum",
      status: "dikembalikan",
    },
    {
      id: 4,
      title: "Buku D",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos ut doloribus eos excepturi quasi ipsum voluptatibus amet consequatur quibusdam accusantium at ad dolore quam tempore quisquam quo nulla mollitia beatae, voluptas nobis eveniet asperiores neque commodi numquam. Soluta ab quo corporis quod at consequuntur veritatis, fuga a minima alias ipsum",
      status: "denda",
    },
    {
      id: 5,
      title: "Buku E",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos ut doloribus eos excepturi quasi ipsum voluptatibus amet consequatur quibusdam accusantium at ad dolore quam tempore quisquam quo nulla mollitia beatae, voluptas nobis eveniet asperiores neque commodi numquam. Soluta ab quo corporis quod at consequuntur veritatis, fuga a minima alias ipsum",
      status: "pengajuan",
    },
  ];

  // Kategori dengan "semua"
  const categories = [
    "dipesan",
    "pengajuan",
    "dipinjam",
    "dikembalikan",
    "dibatalkan",
    "denda",
  ] as const;

  const filteredData = borrowData.filter(
    (item) => item.status === selectedCategory
  );

  // Function to handle checkbox change
  const handleCheckboxChange = (id: number) => {
    setCheckedItems((prev) => {
      const newCheckedItems = new Set(prev);
      if (newCheckedItems.has(id)) {
        newCheckedItems.delete(id);
      } else {
        newCheckedItems.add(id);
      }
      return newCheckedItems;
    });
  };

  // Function to get the status label, its styling, and checked item count
  const getStatusLabel = (status: BorrowStatus["status"]) => {
    const statusLabels: { [key in BorrowStatus["status"]]: string | null } = {
      dipesan: "Ajukan",
      pengajuan: "Batalkan",
      dipinjam: "Kembalikan",
      dikembalikan: "Ajukan",
      dibatalkan: "Ajukan",
      denda: "Bayar",
    };

    const labelText = statusLabels[status];

    const checkedCount = filteredData.filter((item) =>
      checkedItems.has(item.id)
    ).length;

    return labelText ? (
      <p>{checkedCount > 0 ? `(${checkedCount}) ${labelText}` : labelText}</p>
    ) : null;
  };

  return (
    <div className="p-4 font-inter">
      <h1 className="text-xl font-bold mb-4">Status Pinjaman Buku</h1>
      <div className="flex overflow-x-auto space-x-4 mb-4">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={`px-4 outline-none py-2 rounded-md ${
              selectedCategory === category
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-600"
            }`}
          >
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </button>
        ))}
      </div>

      <div className={`flex justify-end my-[10px] `}>
        <p className="bg-blue-600 text-white py-1 px-6 rounded-lg">
          {getStatusLabel(selectedCategory)}
        </p>
      </div>

      <div className="space-y-2">
        {filteredData.length > 0 ? (
          filteredData.map((item) => (
            <div key={item.id} className="border p-2 rounded-md">
              <div className="flex items-center gap-[10px]">
                <input
                  type="checkbox"
                  checked={checkedItems.has(item.id)}
                  onChange={() => handleCheckboxChange(item.id)}
                  className="h-[30px] w-[30px] rounded"
                />
                <div className="w-[100px] h-[80px]">
                  <img
                    src="https://picsum.photos/200/300?random=5"
                    alt=""
                    className="w-full h-full object-cover rounded-md"
                  />
                </div>
                <div className="w-[250px] lg:w-full">
                  <h2 className="text-lg font-semibold">{item.title}</h2>
                  <p className="text-[14px] lg:hidden">
                    {TruncateText(item.desc, 50)}
                  </p>{" "}
                  <p className="text-[14px] lg:block hidden">
                    {TruncateText(item.desc, 100)}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Tidak ada buku di kategori ini.</p>
        )}
      </div>
    </div>
  );
};

export default StatusBorrow;
