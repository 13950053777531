export const IconUser = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 18L14 18M17 15V21M7.68213 14C8.63244 14.6318 9.77319 15 10.9999 15C11.7012 15 12.3744 14.8797 13 14.6586M10.5 21H5.6C5.03995 21 4.75992 21 4.54601 20.891C4.35785 20.7951 4.20487 20.6422 4.10899 20.454C4 20.2401 4 19.9601 4 19.4V17C4 15.3431 5.34315 14 7 14H7.5M15 7C15 9.20914 13.2091 11 11 11C8.79086 11 7 9.20914 7 7C7 4.79086 8.79086 3 11 3C13.2091 3 15 4.79086 15 7Z"
      stroke={fill}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const IconLike = (width: string, height: string, fill: string) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M160,224H53.333C23.936,224,0,247.936,0,277.333V448c0,29.397,23.936,53.333,53.333,53.333h64
				c29.397,0,53.333-23.936,53.333-53.333V234.667C170.667,228.779,165.888,224,160,224z"
          />
          <path
            d="M512,304c0-12.821-5.099-24.768-13.867-33.6c9.963-10.901,15.04-25.536,13.632-40.725
				c-2.496-27.115-26.944-48.341-55.637-48.341H324.373c6.507-19.819,16.96-56.149,16.96-85.333c0-46.272-39.317-85.333-64-85.333
				c-22.165,0-37.995,12.459-38.677,12.992c-2.539,2.027-3.989,5.099-3.989,8.341v72.32l-47.957,103.893
				c-1.472,3.221-1.28,6.955,0.555,9.984c3.136,5.184,4.736,10.731,4.736,16.448v213.333c0,5.141-0.597,10.368-1.835,16.021
				c-1.259,5.739,2.347,11.413,8.064,12.693c9.365,2.112,18.539,3.285,25.771,3.285h195.819c23.232,0,43.563-15.659,48.32-37.269
				c2.453-11.115,1.003-22.336-3.84-32.043c15.765-7.936,26.368-24.192,26.368-42.688c0-7.531-1.728-14.784-5.013-21.333
				C501.397,338.731,512,322.496,512,304z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const IconChat = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6288 20.4718L13.0867 21.3877C12.6035 22.204 11.3965 22.204 10.9133 21.3877L10.3712 20.4718C9.95073 19.7614 9.74049 19.4063 9.40279 19.2098C9.06509 19.0134 8.63992 19.0061 7.78958 18.9915C6.53422 18.9698 5.74689 18.8929 5.08658 18.6194C3.86144 18.1119 2.88807 17.1386 2.3806 15.9134C2 14.9946 2 13.8297 2 11.5V10.5C2 7.22657 2 5.58985 2.7368 4.38751C3.14908 3.71473 3.71473 3.14908 4.38751 2.7368C5.58985 2 7.22657 2 10.5 2H13.5C16.7734 2 18.4101 2 19.6125 2.7368C20.2853 3.14908 20.8509 3.71473 21.2632 4.38751C22 5.58985 22 7.22657 22 10.5V11.5C22 13.8297 22 14.9946 21.6194 15.9134C21.1119 17.1386 20.1386 18.1119 18.9134 18.6194C18.2531 18.8929 17.4658 18.9698 16.2104 18.9915C15.36 19.0061 14.9349 19.0134 14.5972 19.2098C14.2595 19.4062 14.0492 19.7614 13.6288 20.4718ZM8 11.75C7.58579 11.75 7.25 12.0858 7.25 12.5C7.25 12.9142 7.58579 13.25 8 13.25H13.5C13.9142 13.25 14.25 12.9142 14.25 12.5C14.25 12.0858 13.9142 11.75 13.5 11.75H8ZM7.25 9C7.25 8.58579 7.58579 8.25 8 8.25H16C16.4142 8.25 16.75 8.58579 16.75 9C16.75 9.41421 16.4142 9.75 16 9.75H8C7.58579 9.75 7.25 9.41421 7.25 9Z"
      fill={fill}
    />
  </svg>
);

export const IconBack = (width: string, height: string, fill: string) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 472.615 472.615"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M167.158,117.315l-0.001-77.375L0,193.619l167.157,153.679v-68.555c200.338,0.004,299.435,153.932,299.435,153.932
			c3.951-19.967,6.023-40.609,6.023-61.736C472.615,196.295,341.8,117.315,167.158,117.315z"
        />
      </g>
    </g>
  </svg>
);

export const IconSend = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    id="send-alt"
    className="icon glyph"
  >
    <path
      d="M21.88,4.73,16.2,20.65A2,2,0,0,1,14.3,22h0a2,2,0,0,1-1.9-1.31l-2.12-5.52,1.54-1.54,2.49-2.49a1,1,0,1,0-1.42-1.42l-2.49,2.49L8.82,13.76,3.31,11.63a2,2,0,0,1,0-3.83L19.27,2.12a2,2,0,0,1,2.61,2.61Z"
      className="fill:{fill}"
    ></path>
  </svg>
);

export const IconChevron = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      stroke={fill}
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M4 7l6 6 6-6"
    />
  </svg>
);

export const IconExit = (width: string, height: string, fill: string) => (
  <svg
    viewBox="0 0 600 600"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
  >
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M 130 0 C 58.672 0 0 58.672 0 130 L 0 470 C 0 541.328 58.672 600 130 600 L 301.578 600 C 367.833 600 423.136 549.367 430.672 485 L 349.436 485 C 343.322 505.66 324.704 520 301.578 520 L 130 520 C 101.608 520 80 498.392 80 470 L 80 130 C 80 101.608 101.608 80 130 80 L 301.578 80 C 324.704 80 343.322 94.34 349.436 115 L 430.672 115 C 423.136 50.633 367.833 0 301.578 0 L 130 0 z"
    />
    <path
      style={{ strokeLinecap: "round" }}
      d="M 476.863 180 a 40 40 0 0 0 -28.285 11.715 a 40 40 0 0 0 0 56.57 l 11.715 11.715 H 163.727 a 40 40 0 0 0 -40 40 a 40 40 0 0 0 40 40 h 296.566 l -11.715 11.715 a 40 40 0 0 0 0 56.57 a 40 40 0 0 0 56.57 0 l 72.791 -72.791 a 40 40 0 0 0 0 -56.57 a 40 40 0 0 0 -56.57 0 l -72.38 -72.38 a 40 40 0 0 0 -28.285 -11.715 z"
    />
  </svg>
);

export const IconClose = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Menu / Close_SM">
      <path
        id="Vector"
        d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export const IconPlus = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 12H18M12 6V18"
      stroke={fill}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const IconMinus = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 12L18 12"
      stroke={fill}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const IconCustomer = (width: string, height: string, fill: string) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g data-name="19 costumer service" id="_19_costumer_service">
      <path d="M37.71,34.54a13.428,13.428,0,0,0,5.51-4.86H46.7a2.915,2.915,0,0,0,2.74-3.06V19.43a3.124,3.124,0,0,0-.2-1.11V18.3a25.246,25.246,0,0,0-5.2-9.54l-.13-.15a15.275,15.275,0,0,0-5.72-3.97,17.135,17.135,0,0,0-14.11.8,15.539,15.539,0,0,0-3.99,3.17l-.13.15a25.246,25.246,0,0,0-5.2,9.54v.02a3.124,3.124,0,0,0-.2,1.11v7.19a2.915,2.915,0,0,0,2.74,3.06h3.48a13.428,13.428,0,0,0,5.51,4.86,20.226,20.226,0,0,0-15.02,19.5V59.5a1,1,0,0,0,1,1H51.73a1,1,0,0,0,1-1V54.04A20.226,20.226,0,0,0,37.71,34.54Zm9.73-15.11v7.19c0,.56-.35,1.06-.74,1.06H44.29c.17-.42.33-.85.46-1.29,0-.01,0-.01.01-.02a13.235,13.235,0,0,0-.1-8H46.7C47.09,18.37,47.44,18.87,47.44,19.43ZM17.3,27.68c-.39,0-.74-.5-.74-1.06V19.43c0-.56.35-1.06.74-1.06h2.04a13.261,13.261,0,0,0,.37,9.31Zm.26-11.31a22.694,22.694,0,0,1,3.89-6.28l.12-.13A13.124,13.124,0,0,1,25.05,7.2a15.268,15.268,0,0,1,12.41-.7,13.159,13.159,0,0,1,4.97,3.46l.12.13a22.694,22.694,0,0,1,3.89,6.28H43.82a13.311,13.311,0,0,0-23.64,0Zm3.11,6.14A11.33,11.33,0,1,1,43.05,25H39.71a2.991,2.991,0,0,0-2.82-2h-3a3,3,0,0,0,0,6h3a2.991,2.991,0,0,0,2.82-2H42.4a11.329,11.329,0,0,1-21.73-4.49Zm17.22,3.48v.02a.994.994,0,0,1-1,.99h-3a1,1,0,0,1,0-2h3A.994.994,0,0,1,37.89,25.99ZM50.73,58.5H13.27V54.04a18.228,18.228,0,0,1,18.2-18.2h1.06a18.228,18.228,0,0,1,18.2,18.2Z" />
    </g>
  </svg>
);

export const IconEye = () => (
  <svg
    fill="gray"
    width="24px"
    height="24px"
    viewBox="0 -2.96 15.929 15.929"
    id="eye-16px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path_112"
      data-name="Path 112"
      d="M-3.768,6.232l-.416-.416A9.609,9.609,0,0,0-11,2.993a9.609,9.609,0,0,0-6.816,2.823l-.416.416a2.5,2.5,0,0,0,0,3.536l.416.416A9.609,9.609,0,0,0-11,13.007a9.609,9.609,0,0,0,6.816-2.823l.416-.416A2.5,2.5,0,0,0-3.768,6.232ZM-11,4a.5.5,0,0,1,.5.5A.5.5,0,0,1-11,5a2,2,0,0,0-2,2,.5.5,0,0,1-.5.5A.5.5,0,0,1-14,7,3,3,0,0,1-11,4Zm6.525,5.061-.416.416A8.581,8.581,0,0,1-11,12.007a8.581,8.581,0,0,1-6.109-2.53l-.416-.416A1.493,1.493,0,0,1-17.964,8a1.493,1.493,0,0,1,.439-1.061l.416-.416A8.624,8.624,0,0,1-14.183,4.6,3.964,3.964,0,0,0-15,7a4,4,0,0,0,4,4A4,4,0,0,0-7,7a3.964,3.964,0,0,0-.817-2.4A8.624,8.624,0,0,1-4.891,6.523l.416.416A1.493,1.493,0,0,1-4.036,8,1.493,1.493,0,0,1-4.475,9.061Z"
      transform="translate(18.965 -2.993)"
    />
  </svg>
);

export const IconEyesClose = () => (
  <svg
    fill="gray"
    width="24px"
    height="24px"
    viewBox="0 0 16 16"
    id="eye-off-16px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path_110"
      data-name="Path 110"
      d="M32.716,11.837l.823.823a9.6,9.6,0,0,1-9.355-2.476l-.416-.416a2.5,2.5,0,0,1,0-3.536l.416-.416A9.653,9.653,0,0,1,25.56,4.681l.717.717a8.76,8.76,0,0,0-1.386,1.125l-.416.416a1.5,1.5,0,0,0,0,2.122l.416.416A8.581,8.581,0,0,0,31,12.007,8.729,8.729,0,0,0,32.716,11.837ZM31,11a4.037,4.037,0,0,0,.8-.08L27.08,6.2A4,4,0,0,0,31,11ZM29.525,4.4A2.958,2.958,0,0,1,31,4a.5.5,0,0,1,0,1,1.976,1.976,0,0,0-.735.144L34.331,9.21A3.956,3.956,0,0,0,34.183,4.6a8.624,8.624,0,0,1,2.926,1.922l.416.416a1.5,1.5,0,0,1,0,2.122l-.416.416A8.7,8.7,0,0,1,35.723,10.6l.717.718a9.653,9.653,0,0,0,1.376-1.135l.416-.416a2.5,2.5,0,0,0,0-3.536l-.416-.416A9.6,9.6,0,0,0,28.461,3.34ZM23.854.146a.5.5,0,0,0-.708.708l15,15a.5.5,0,0,0,.708-.708Z"
      transform="translate(-23 0)"
    />
  </svg>
);

export const IconExport = (width: string, height: string, fill: string) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.42 12.06L14.3467 13.1333C14.2133 13.2667 14.0467 13.3267 13.8733 13.3267C13.7 13.3267 13.5333 13.26 13.4 13.1333C13.14 12.8733 13.14 12.4533 13.4 12.1933L14.26 11.3333H9.32667C8.96 11.3333 8.66 11.0333 8.66 10.6667C8.66 10.3 8.96 10 9.32667 10H14.26L13.4 9.14C13.14 8.88 13.14 8.46 13.4 8.2C13.66 7.94 14.08 7.94 14.34 8.2L15.4133 9.27333C16.18 10.04 16.18 11.2933 15.4133 12.0667L15.42 12.06ZM8.66667 5.33333H13.0267C12.7933 4.72667 12.44 4.16667 11.9667 3.69333L9.64667 1.36667C9.17333 0.893333 8.61333 0.54 8.00667 0.306667V4.66667C8.00667 5.03333 8.30667 5.33333 8.67333 5.33333H8.66667ZM11.8733 12.6667H9.33333C8.23333 12.6667 7.33333 11.7667 7.33333 10.6667C7.33333 9.56667 8.23333 8.66667 9.33333 8.66667H11.8733C11.8733 8.15333 12.0667 7.64 12.46 7.25333C12.7067 7.00667 13.0067 6.83333 13.32 6.74667V6.66667H8.66667C7.56667 6.66667 6.66667 5.76667 6.66667 4.66667V0.0133333C6.56 0.00666667 6.45333 0 6.34 0H3.33333C1.49333 0 0 1.49333 0 3.33333V12.6667C0 14.5067 1.49333 16 3.33333 16H10C11.2067 16 12.2667 15.3533 12.8533 14.3867C12.7133 14.3 12.58 14.2 12.46 14.08C12.0733 13.6933 11.8733 13.18 11.8733 12.6667Z" />
  </svg>
);
