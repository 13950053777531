// GoingClass.tsx

import React from "react";

interface Attendance {
  status: "Hadir" | "Tidak Hadir";
  date: string;
  subject: string;
  summary: string;
}

const attendanceHistory: Attendance[] = [
  {
    status: "Hadir",
    date: "2024-08-28",
    subject: "Matematika",
    summary: "Mempelajari konsep dasar aljabar dan persamaan linear.",
  },
  {
    status: "Tidak Hadir",
    date: "2024-08-27",
    subject: "Bahasa Inggris",
    summary: "Tidak hadir karena sakit. Materi: Tenses dalam Bahasa Inggris.",
  },
  // Tambahkan data riwayat kehadiran lainnya sesuai kebutuhan
];

const GoingClass: React.FC = () => {
  return (
    <div className="min-h-screen font-inter bg-gray-100 p-4">
      <div className="max-w-4xl mx-auto bg-white rounded-3xl shadow-2xl p-4">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">
          Absensi Masuk Kelas
        </h1>

        {/* Form Absensi */}
        <div className="mb-8 p-4 bg-blue-50 border border-blue-200 rounded-xl shadow-md">
          <h2 className="text-2xl font-semibold text-blue-600 mb-4">
            Absensi Hari Ini
          </h2>
          <form>
            <div className="flex items-center space-x-4 mb-4">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="attendance"
                  value="Hadir"
                  className="form-radio text-blue-600"
                />
                <span className="text-gray-700">Hadir</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="attendance"
                  value="Tidak Hadir"
                  className="form-radio text-red-600"
                />
                <span className="text-gray-700">Tidak Hadir</span>
              </label>
            </div>
            <div className="mb-4">
              <textarea
                rows={4}
                placeholder="Keterangan rangkuman pembelajaran"
                className="w-full p-3 border border-gray-300 rounded-lg resize-none"
              ></textarea>
            </div>
            <button
              type="submit"
              className="px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
            >
              Kirim Absensi
            </button>
          </form>
        </div>

        {/* Riwayat Kehadiran */}
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Riwayat Kehadiran
          </h2>
          {attendanceHistory.map((entry, index) => (
            <div
              key={index}
              className="mb-6 p-4 border border-gray-200 rounded-xl bg-white shadow-sm"
            >
              <div className="flex justify-between mb-3">
                <span
                  className={`text-lg font-medium ${
                    entry.status === "Hadir" ? "text-green-600" : "text-red-600"
                  }`}
                >
                  {entry.status}
                </span>
                <span className="text-gray-500">{entry.date}</span>
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                {entry.subject}
              </h3>
              <p className="text-gray-700">{entry.summary}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GoingClass;
