import React from "react";
interface AddDailyValueProps {
  onClose: () => void;
}
const AddDailyValue: React.FC<AddDailyValueProps> = ({ onClose }) => {
  return (
    <div className="mb-6 p-4 bg-white rounded shadow-md">
      <h2 className="text-xl font-semibold mb-4">Add New Student Data</h2>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-4">
        <input
          type="text"
          placeholder="Student Name"
          className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <input
          type="number"
          placeholder="Week 1"
          className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <input
          type="number"
          placeholder="Week 2"
          className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <input
          type="number"
          placeholder="Week 3"
          className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <input
          type="number"
          placeholder="Week 4"
          className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>
      <button
        onClick={onClose}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
      >
        Simpan
      </button>
    </div>
  );
};

export default AddDailyValue;
