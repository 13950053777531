import React from "react";
import TruncateText from "../../../Components/truncateText";
import { IconUser } from "../../../Assets/Icons";
import { Link } from "react-router-dom";

const DataValue = [
  {
    id: 1,
    background: "https://picsum.photos/200/300?random=1",
    profil: "https://picsum.photos/200/300?random=2",
    sekolah: "Abdul Majid",
    desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem accusantium vel harum voluptas cum praesentium illum nam ea id inventore, velit asperiores. Voluptate sint, in dolore qui assumenda    natus odit iste? Laboriosam dolores fugit vitae incidunt molestias  labore dolorum maxime quam, ex at, ipsam repudiandae nemo, quasi et       aliquam fuga!",
  },
  {
    id: 2,
    background: "https://picsum.photos/200/300?random=3",
    profil: "https://picsum.photos/200/300?random=4",
    sekolah: "Ainul Yaqin",
    desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem accusantium vel harum voluptas cum praesentium illum nam ea id inventore, velit asperiores. Voluptate sint, in dolore qui assumenda    natus odit iste? Laboriosam dolores fugit vitae incidunt molestias  labore dolorum maxime quam, ex at, ipsam repudiandae nemo, quasi et       aliquam fuga!",
  },
  {
    id: 3,
    background: "https://picsum.photos/200/300?random=5",
    profil: "https://picsum.photos/200/300?random=6",
    sekolah: "Bro Alex Simatupang",
    desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem accusantium vel harum voluptas cum praesentium illum nam ea id inventore, velit asperiores. Voluptate sint, in dolore qui assumenda    natus odit iste? Laboriosam dolores fugit vitae incidunt molestias  labore dolorum maxime quam, ex at, ipsam repudiandae nemo, quasi et       aliquam fuga!",
  },
  {
    id: 4,
    background: "https://picsum.photos/200/300?random=7",
    profil: "https://picsum.photos/200/300?random=8",
    sekolah: "Pahmi",
    desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem accusantium vel harum voluptas cum praesentium illum nam ea id inventore, velit asperiores. Voluptate sint, in dolore qui assumenda    natus odit iste? Laboriosam dolores fugit vitae incidunt molestias  labore dolorum maxime quam, ex at, ipsam repudiandae nemo, quasi et       aliquam fuga!",
  },
];

const OrangResults: React.FC = () => (
  <section>
    {DataValue.map((result) => (
      <div
        key={result.id}
        className="relative border p-4 mb-4 rounded-lg shadow-md bg-cover bg-center text-white"
        style={{ backgroundImage: `url(${result.background})` }}
      >
        <div className=" p-4 bg-black bg-opacity-50 rounded-lg">
          <div className="flex justify-end w-full">
            <div className="flex">
              {IconUser("24", "24", "white")}
              <button className="">Ikuti</button>
            </div>
          </div>
          <Link to={"/profile"}>
            <div className="lg:flex font-inter   lg:gap-10 items-center">
              <img
                src={result.profil}
                alt={result.sekolah}
                className="w-24 h-24 object-cover rounded-full mb-2 border-4 border-white"
              />
              <div className="lg:w-[400px] ">
                <h2 className="text-[18px] font-semibold">{result.sekolah}</h2>
                <p className="text-gray-200 text-[13px] lg:text-left text-center mt-2">
                  {TruncateText(result.desc, 100)}
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    ))}
  </section>
);

export default OrangResults;
