import React from "react";
import { Link } from "react-router-dom";

interface Article {
  id: number;
  title: string;
  content: string;
  category: "Kesulitan Belajar" | "Pemilihan Jurusan" | "Tekanan Akademik";
  image: string; // URL gambar
}

const articles: Article[] = [
  {
    id: 1,
    title: "Mengatasi Kesulitan Belajar di Sekolah",
    content:
      "Kesulitan belajar adalah tantangan yang umum dihadapi siswa. Dalam artikel ini, kita akan membahas berbagai cara untuk mengatasi masalah ini, mulai dari teknik belajar yang efektif hingga dukungan dari keluarga dan guru.",
    category: "Kesulitan Belajar",
    image: "https://via.placeholder.com/400x250?text=Kesulitan+Belajar", // Ganti dengan URL gambar sebenarnya
  },
  {
    id: 2,
    title: "Tips Memilih Jurusan Kuliah yang Tepat",
    content:
      "Memilih jurusan kuliah bisa menjadi keputusan yang menakutkan. Artikel ini memberikan panduan untuk membantu Anda menentukan jurusan yang sesuai dengan minat dan kemampuan Anda, serta bagaimana menghindari kesalahan umum.",
    category: "Pemilihan Jurusan",
    image: "https://via.placeholder.com/400x250?text=Tekanan+Akademik", // Ganti dengan URL gambar sebenarnya
  },
  {
    id: 3,
    title: "Mengelola Tekanan Akademik di Kampus",
    content:
      "Tekanan akademik bisa menjadi beban yang berat bagi mahasiswa. Di artikel ini, kami menawarkan strategi untuk mengelola stres akademik dan cara menjaga keseimbangan antara studi dan kehidupan pribadi.",
    category: "Tekanan Akademik",
    image: "https://via.placeholder.com/400x250?text=Tekanan+Akademik", // Ganti dengan URL gambar sebenarnya
  },
];

const ConsolidationArtikel: React.FC = () => {
  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };
  return (
    <div className="container font-inter mx-auto p-4 ">
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-4 gap-2">
        {articles.map((article) => (
          <Link to={"detail-artikel"}>
            <div
              key={article.id}
              className="bg-white p-4 shadow-lg rounded-lg overflow-hidden flex gap-2"
            >
              <div className="w-[150px] h-auto">
                <img
                  src={article.image}
                  alt={article.title}
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
              <div className="w-[250px]">
                <h2 className="text-[16px] font-semibold mb-1">
                  {article.title}
                </h2>
                {/* batasin teksnya 200 jika lebih maka kasih titiik 3 */}
                <p className="text-gray-700 text-[11px]">
                  {truncateText(article.content, 108)}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ConsolidationArtikel;
