import React from "react";
import { Link } from "react-router-dom";

interface JobPosting {
  id: string;
  title: string;
  company: string;
  location: string;
  description: string;
  requirements: string[];
  image: string;
  postedDate: string;
}

const jobPostings: JobPosting[] = [
  {
    id: "1",
    title: "Frontend Developer Intern",
    company: "Tech Company A",
    location: "Jakarta, Indonesia",
    description:
      "Join our team as a Frontend Developer Intern and help us build user-friendly web applications.",
    requirements: [
      "Experience with React.js",
      "Familiarity with HTML, CSS, and JavaScript",
      "Good problem-solving skills",
    ],
    image: "https://picsum.photos/200/300?random=1",
    postedDate: "2024-08-01",
  },
  {
    id: "2",
    title: "UI/UX Designer Intern",
    company: "Design Studio B",
    location: "Bandung, Indonesia",
    description:
      "We are looking for a creative UI/UX Designer Intern to join our design team.",
    requirements: [
      "Experience with design tools like Figma or Sketch",
      "Strong portfolio of design projects",
      "Understanding of user-centered design principles",
    ],
    image: "https://picsum.photos/200/300?random=2",
    postedDate: "2024-08-05",
  },
  {
    id: "3",
    title: "Backend Developer Intern",
    company: "Startup C",
    location: "Surabaya, Indonesia",
    description:
      "As a Backend Developer Intern, you will be working on our core backend systems.",
    requirements: [
      "Experience with Node.js or Python",
      "Understanding of RESTful APIs",
      "Database management knowledge",
    ],
    image: "https://picsum.photos/200/300?random=4",
    postedDate: "2024-08-10",
  },
];

const WorkPage: React.FC = () => {
  return (
    <div className="container mx-auto mt-[20px] p-4">
      <h1 className="text-4xl font-bold text-center text-blue-600 mb-10">
        Internship Opportunities
      </h1>
      <div className="grid grid-cols-1  gap-8">
        {jobPostings.map((job) => (
          <div
            key={job.id}
            className="bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300"
          >
            <img
              src={job.image}
              alt={job.title}
              className="w-full h-48 lg:h-55 object-cover rounded-t-xl mb-4"
            />
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              {job.title}
            </h2>
            <p className="text-gray-600">{job.company}</p>
            <p className="text-gray-500 mb-2">{job.location}</p>
            <p className="text-gray-400 text-sm mb-4">
              Posted on: {new Date(job.postedDate).toLocaleDateString()}
            </p>
            <p className="text-gray-700 mb-4">{job.description}</p>
            <ul className="list-disc list-inside mb-4 text-gray-600">
              {job.requirements.map((req, index) => (
                <li key={index}>{req}</li>
              ))}
            </ul>
            <Link to={"detail"}>
              <button className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-300">
                Apply Now
              </button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkPage;
