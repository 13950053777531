import React from "react";

interface Education {
  schoolName: string;
  degree: string;
  fieldOfStudy: string;
  startDate: string;
  endDate: string;
  description: string;
  type: "formal" | "non-formal";
}

const educationData: Education[] = [
  {
    schoolName: "SMA Negeri 1 Jakarta",
    degree: "SMA",
    fieldOfStudy: "Ilmu Pengetahuan Alam",
    startDate: "2016",
    endDate: "2019",
    description:
      "Memperdalam ilmu pengetahuan alam dengan fokus pada mata pelajaran Fisika, Kimia, dan Biologi.",
    type: "formal",
  },
  {
    schoolName: "Universitas Indonesia",
    degree: "Sarjana",
    fieldOfStudy: "Teknik Informatika",
    startDate: "2019",
    endDate: "2023",
    description:
      "Belajar tentang pengembangan perangkat lunak, algoritma, dan sistem komputer dengan spesialisasi dalam pemrograman web.",
    type: "formal",
  },
  {
    schoolName: "Kursus Programming Online",
    degree: "Sertifikat",
    fieldOfStudy: "Pemrograman Web",
    startDate: "2021",
    endDate: "2021",
    description:
      "Kursus intensif selama 3 bulan tentang dasar-dasar pengembangan web menggunakan HTML, CSS, dan JavaScript.",
    type: "non-formal",
  },
  {
    schoolName: "Bootcamp Data Science",
    degree: "Sertifikat",
    fieldOfStudy: "Data Science",
    startDate: "2022",
    endDate: "2022",
    description:
      "Pelatihan singkat yang mencakup analisis data, machine learning, dan visualisasi data menggunakan Python dan R.",
    type: "non-formal",
  },
];

const PendidikanProfile: React.FC = () => {
  const formalEducation = educationData.filter((edu) => edu.type === "formal");
  const nonFormalEducation = educationData.filter(
    (edu) => edu.type === "non-formal"
  );

  return (
    <div className="p-6 bg-white shadow rounded-lg">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        Riwayat Pendidikan
      </h2>

      <div className="mb-6">
        <h3 className="text-xl font-semibold text-blue-600 mb-3">
          Pendidikan Formal
        </h3>
        <ul className="space-y-4">
          {formalEducation.map((edu, index) => (
            <li key={index} className="border-b border-gray-200 pb-4">
              <p className="text-lg font-medium text-gray-700">
                {edu.schoolName}
              </p>
              <p className="text-sm text-gray-500">
                {edu.degree} - {edu.fieldOfStudy}
              </p>
              <p className="text-sm text-gray-400">
                {edu.startDate} - {edu.endDate}
              </p>
              <p className="text-sm text-gray-600 mt-2">{edu.description}</p>
            </li>
          ))}
        </ul>
      </div>

      <div>
        <h3 className="text-xl font-semibold text-green-600 mb-3">
          Pendidikan Non-Formal
        </h3>
        <ul className="space-y-4">
          {nonFormalEducation.map((edu, index) => (
            <li key={index} className="border-b border-gray-200 pb-4">
              <p className="text-lg font-medium text-gray-700">
                {edu.schoolName}
              </p>
              <p className="text-sm text-gray-500">
                {edu.degree} - {edu.fieldOfStudy}
              </p>
              <p className="text-sm text-gray-400">
                {edu.startDate} - {edu.endDate}
              </p>
              <p className="text-sm text-gray-600 mt-2">{edu.description}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PendidikanProfile;
