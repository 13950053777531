// MaterialSpace.tsx

import React, { useState } from "react";

interface Material {
  title: string;
  description: string;
  link: string;
  category: "modul" | "tambahan";
}

const materials: Material[] = [
  {
    title: "Modul Pembelajaran Matematika",
    description: "Modul lengkap mengenai konsep dasar aljabar.",
    link: "/path/to/modul-pembelajaran-matematika.pdf",
    category: "modul",
  },
  {
    title: "Materi Tambahan Bahasa Inggris",
    description:
      "Dokumen tambahan untuk meningkatkan kemampuan bahasa Inggris.",
    link: "/path/to/materi-tambahan-bahasa-inggris.pdf",
    category: "tambahan",
  },
  {
    title: "Modul Pembelajaran Matematika",
    description: "Modul lengkap mengenai konsep dasar aljabar.",
    link: "/path/to/modul-pembelajaran-matematika.pdf",
    category: "modul",
  },
  {
    title: "Materi Tambahan Bahasa Inggris",
    description:
      "Dokumen tambahan untuk meningkatkan kemampuan bahasa Inggris.",
    link: "/path/to/materi-tambahan-bahasa-inggris.pdf",
    category: "tambahan",
  },
  // Tambahkan materi lainnya sesuai kebutuhan
];

const MaterialSpace: React.FC = () => {
  const [activeCategory, setActiveCategory] = useState<"modul" | "tambahan">(
    "modul"
  );

  const filteredMaterials = materials.filter(
    (material) => material.category === activeCategory
  );

  return (
    <div className="min-h-screen font-inter bg-gray-100 p-4">
      <div className="max-w-4xl mx-auto bg-white rounded-3xl shadow-lg p-4">
        <h1 className="text-4xl font-bold text-gray-800 mb-8 text-center">
          Material Pembelajaran
        </h1>

        {/* Kategori Buttons */}
        <div className="flex justify-center mb-8 space-x-4">
          <button
            onClick={() => setActiveCategory("modul")}
            className={`px-6 py-3 text-[18px] font-semibold rounded-lg shadow-md focus:outline-none ${
              activeCategory === "modul"
                ? "bg-blue-600 text-white hover:bg-blue-700"
                : "bg-gray-200 text-gray-800 hover:bg-gray-300"
            }`}
          >
            Modul Pembelajaran
          </button>
          <button
            onClick={() => setActiveCategory("tambahan")}
            className={`px-6 py-3 text-lg font-semibold rounded-lg shadow-md focus:outline-none ${
              activeCategory === "tambahan"
                ? "bg-blue-600 text-white hover:bg-blue-700"
                : "bg-gray-200 text-gray-800 hover:bg-gray-300"
            }`}
          >
            Materi Tambahan
          </button>
        </div>

        {/* Material List */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredMaterials.length > 0 ? (
            filteredMaterials.map((material, index) => (
              <div
                key={index}
                className="bg-white p-6 border border-gray-200 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300"
              >
                <div className="flex flex-col h-full">
                  <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    {material.title}
                  </h2>
                  <p className="text-gray-700 mb-4 flex-grow">
                    {material.description}
                  </p>
                  <a
                    href={material.link}
                    download
                    className="inline-block px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
                  >
                    Unduh PDF
                  </a>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-600 text-center col-span-full">
              Tidak ada materi untuk kategori ini.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MaterialSpace;
