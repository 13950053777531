import React from "react";
import { IconBack, IconClose, IconSend } from "../../../Assets/Icons";
import { ImageProfil } from "../../../Assets/Images";

interface ChatMessage {
  id: number;
  sender: "user" | "other";
  message: string;
  time: string;
}

interface ChattingProps {
  onClose: () => void;
}

const messages: ChatMessage[] = [
  { id: 1, sender: "user", message: "Hi! How are you?", time: "10:00 AM" },
  {
    id: 2,
    sender: "other",
    message: "I’m good, thank you! How about you?",
    time: "10:01 AM",
  },
  {
    id: 3,
    sender: "user",
    message: "I’m doing great! Thanks for asking.",
    time: "10:02 AM",
  },
  {
    id: 3,
    sender: "user",
    message: "I’m doing great! Thanks for asking.",
    time: "10:02 AM",
  },
  {
    id: 3,
    sender: "user",
    message: "I’m doing great! Thanks for asking.",
    time: "10:02 AM",
  },
  { id: 4, sender: "other", message: "Glad to hear that!", time: "10:03 AM" },
];

const Chatting: React.FC<ChattingProps> = ({ onClose }) => {
  return (
    <section className="relative font-inter flex flex-col h-screen lg:h-[440px]  ">
      <div className="flex fixed bg-white rounded-b-md border-b shadow-sm w-full lg:w-[350px] py-[5px] mt-[-20px] lg:mt-0 lg:py-[8px] px-4 justify-between items-center ">
        <div className="flex items-center gap-3">
          <div className="w-12 h-12">
            <img
              src={"https://via.placeholder.com/50"}
              alt="Profile Picture"
              className="w-full h-full object-cover rounded-full"
            />
          </div>
          <div>
            <p className="font-semibold">User Name</p>
            <p className="text-sm text-gray-600 lg:text-[10px]">
              Last seen 30 minutes ago
            </p>
          </div>
        </div>
        <button
          onClick={onClose}
          className="p-2 scale-x-[-1] rounded-full hover:bg-gray-200 focus:outline-none"
        >
          {IconClose("30", "30", "blue")}
        </button>
      </div>

      <div className="flex-1 lg:pb-[80px] pb-[130px] px-4 overflow-y-auto lg:h-[100px] bg-slate-100 mb-4 pt-[100px] lg:pt-[80px] border border-slate-300 rounded-lg">
        {messages.map((msg) => (
          <div
            key={msg.id}
            className={`flex ${
              msg.sender === "user" ? "justify-end" : "justify-start"
            } mb-4`}
          >
            <div
              className={`max-w-xs p-3 rounded-lg lg:text-[7px] font-roboto ${
                msg.sender === "user"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-900"
              }`}
            >
              <p className="text-sm">{msg.message}</p>
              <p className="text-xs  text-right">{msg.time}</p>
            </div>
          </div>
        ))}
      </div>

      <div className=" fixed bottom-[60px] lg:bottom-10 rounded-t-lg flex p-2 w-full lg:w-[350px] bg-slate-50">
        <textarea
          placeholder="Type your message..."
          className="w-full p-3 border max-h-[100px] rounded-lg resize-none focus:outline-none focus:border-blue-500"
        />

        <button className="p-2 rounded-full px-[20px]">
          {IconSend("40", "40", "blue")}
        </button>
      </div>
    </section>
  );
};

export default Chatting;
