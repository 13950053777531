import React from "react";
import TruncateText from "../../../Components/truncateText";
import { Link } from "react-router-dom";

const dataValue = [
  {
    id: 1,
    cousers: "SMK 1 PGRI Karawang",
    title: "React Conference 2024",
    description: "Join the React Conference to explore the latest in React.",
    imageUrl: "https://picsum.photos/200/300?random=1",
    timestamp: "2024-10-12T00:00:00Z",
  },
  {
    id: 2,
    cousers: "SMAN 1 Pedes Karawang",
    title: "JavaScript Summit",
    description: "A deep dive into JavaScript for professionals.",
    imageUrl: "https://picsum.photos/200/300?random=2",
    timestamp: "2024-11-05T00:00:00Z",
  },
  {
    id: 3,
    cousers: "PT Niaga",
    title: "CSS Design Workshop",
    description:
      "Learn the best practices in CSS and design.Learn the best practices in CSS and design.Learn the best practices in CSS and design.Learn the best practices in CSS and design.",
    imageUrl: "https://picsum.photos/200/300?random=5",
    timestamp: "2024-09-20T00:00:00Z",
  },
];

const EventResults: React.FC = () => (
  <div className="grid grid-cols-1 font-inter  gap-6">
    {dataValue.map((result) => (
      <div
        key={result.id}
        className="border p-4 rounded-lg shadow-md bg-white transform hover:scale-105 transition-transform duration-300 hover:shadow-lg"
      >
        <div className="w-full h-[130px]">
          <img
            src={result.imageUrl}
            alt=""
            className="w-full h-full object-cover mb-2 rounded-lg"
          />
        </div>
        <div className="flex justify-between">
          <div className="flex ">
            <div className="h-[80px] w-[80px]  mt-[-50px] ml-[10px]">
              <img
                src={result.imageUrl}
                alt=""
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <p className="absolute text-[16px] font-semibold bg-opacity-50 px-4 rounded-md bg-slate-300 ml-[95px] mt-[-30px]">
              {result.cousers}
            </p>
          </div>
          <div className=" bg-blue-500 py-1 px-6 rounded-md text-[14px] shadow font-inter text-white mt-[5px]">
            {result.timestamp && (
              <p className="text-white text-[14px]">
                Tanggal {new Date(result.timestamp).toLocaleDateString()}
              </p>
            )}
          </div>
        </div>
        <h2 className="text-2xl font-semibold mb-2 text-blue-600">
          {TruncateText(result.title, 50)}
        </h2>
        <p className="text-gray-700 mb-4">
          {TruncateText(result.description, 100)}
        </p>
        <Link to={"/detail-event"}>
          <button className="mt-4 py-2 px-4 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition-colors duration-300">
            View Details
          </button>
        </Link>
      </div>
    ))}
  </div>
);

export default EventResults;
