import React, { useState } from "react";
import { IconChevron } from "../../../../Assets/Icons";

// FAQ data
interface FAQItem {
  question: string;
  answer: string;
}

const faqs: FAQItem[] = [
  {
    question: "Apa yang harus saya lakukan jika mengalami kesulitan belajar?",
    answer:
      "Anda bisa menghubungi dosen atau akademik untuk mendapatkan bimbingan belajar.",
  },
  {
    question: "Bagaimana cara memilih jurusan yang tepat?",
    answer:
      "Diskusikan minat dan bakat Anda dengan konselor akademik untuk mendapatkan panduan dalam memilih jurusan.",
  },
  {
    question:
      "Apa yang harus saya lakukan jika merasa tertekan dengan beban akademik?",
    answer:
      "Carilah bantuan dari konselor atau pihak akademik untuk mendapatkan dukungan mental.",
  },
  {
    question:
      "Apa yang harus saya lakukan jika merasa tertekan dengan beban akademik?",
    answer:
      "Carilah bantuan dari konselor atau pihak akademik untuk mendapatkan dukungan mental.",
  },
  {
    question:
      "Apa yang harus saya lakukan jika merasa tertekan dengan beban akademik?",
    answer:
      "Carilah bantuan dari konselor atau pihak akademik untuk mendapatkan dukungan mental.",
  },
];

const FAQSection: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className="mb-12 font-inter p-4">
      <h2 className="text-[18px] font-semibold mb-2 text-gray-700">
        Masalah yang Sering Ditanyakan
      </h2>
      <div className="space-y-4 lg:space-y-1">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="bg-white shadow-lg lg:shadow-sm rounded-lg overflow-hidden"
          >
            <button
              className={`w-full flex justify-between items-center text-left px-4 py-2 text-[14px] font-medium focus:outline-none transition-all ${
                openIndex === index
                  ? "bg-blue-500 text-white"
                  : "bg-white text-gray-700"
              }`}
              onClick={() => handleToggle(index)}
            >
              {faq.question}
              <div
                className={`transform transition-transform duration-200 ${
                  openIndex === index ? "rotate-0" : "-rotate-90"
                }`}
              >
                {IconChevron(
                  "24",
                  "24",
                  openIndex === index ? "white" : "black"
                )}
              </div>
            </button>

            {openIndex === index && (
              <div className="p-2 text-[14px] bg-white border-t border-gray-200">
                <p className="text-gray-600">{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
