// AssignmentRoom.tsx

import React, { useState } from "react";

interface Assignment {
  name: string;
  description: string;
  dueDateStart: string;
  dueDateEnd: string;
  createdDate: string;
  fileLink: string;
}

interface GradedAssignment {
  name: string;
  dueDate: string;
  grade: string;
  description: string;
}

const assignments: Assignment[] = [
  {
    name: "Tugas Matematika Bab 1",
    description: "Kerjakan soal-soal di buku halaman 10-15.",
    dueDateStart: "2024-09-01",
    dueDateEnd: "2024-09-07",
    createdDate: "2024-08-25",
    fileLink: "/path/to/tugas-matematika-bab1.pdf",
  },
  {
    name: "Tugas Bahasa Inggris",
    description: "Buat ringkasan cerita dari buku yang telah dibaca.",
    dueDateStart: "2024-09-05",
    dueDateEnd: "2024-09-10",
    createdDate: "2024-08-28",
    fileLink: "/path/to/tugas-bahasa-inggris.pdf",
  },
  // Tambahkan tugas lainnya sesuai kebutuhan
];

const gradedAssignments: GradedAssignment[] = [
  {
    name: "Tugas Matematika Bab 1",
    dueDate: "2024-09-07",
    grade: "85",
    description:
      "Jawaban cukup baik, perlu memperbaiki cara penyelesaian soal.",
  },
  {
    name: "Tugas Bahasa Inggris",
    dueDate: "2024-09-10",
    grade: "90",
    description: "Ringkasan cerita sudah bagus, teruskan!",
  },
  // Tambahkan tugas lainnya sesuai kebutuhan
];

const AssignmentRoom: React.FC = () => {
  const [activeCategory, setActiveCategory] = useState<"penugasan" | "nilai">(
    "penugasan"
  );
  const [showInput, setShowInput] = useState<number | null>(null);
  const [link, setLink] = useState("");

  const handleSubmit = () => {
    // Proses pengiriman link
    setShowInput(null);
    setLink("");
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-purple-100 p-4">
      <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-lg p-4">
        <h1 className="text-4xl font-bold text-gray-900 mb-8 text-center">
          Ruang Tugas
        </h1>

        {/* Kategori Buttons */}
        <div className="flex justify-center mb-8 space-x-6">
          <button
            onClick={() => setActiveCategory("penugasan")}
            className={`px-6 py-3 text-lg font-semibold rounded-lg transition-transform duration-300 ${
              activeCategory === "penugasan"
                ? "bg-indigo-600 text-white shadow-lg transform scale-105"
                : "bg-gray-200 text-gray-800 hover:bg-gray-300"
            }`}
          >
            Penugasan
          </button>
          <button
            onClick={() => setActiveCategory("nilai")}
            className={`px-6 py-3 text-lg font-semibold rounded-lg transition-transform duration-300 ${
              activeCategory === "nilai"
                ? "bg-indigo-600 text-white shadow-lg transform scale-105"
                : "bg-gray-200 text-gray-800 hover:bg-gray-300"
            }`}
          >
            Nilai Tugas
          </button>
        </div>

        {/* Penugasan List */}
        {activeCategory === "penugasan" && (
          <div className="space-y-6">
            {assignments.map((assignment, index) => (
              <div
                key={index}
                className="bg-white p-6 border border-gray-300 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300"
              >
                <h2 className="text-2xl font-semibold text-indigo-700 mb-4">
                  {assignment.name}
                </h2>
                <p className="text-gray-800 mb-4">{assignment.description}</p>
                <p className="text-gray-500 mb-2">
                  Tanggal Pengumpulan:{" "}
                  <span className="font-medium">
                    {assignment.dueDateStart} - {assignment.dueDateEnd}
                  </span>
                </p>
                <p className="text-gray-500 mb-4">
                  Tanggal Pembuatan:{" "}
                  <span className="font-medium">{assignment.createdDate}</span>
                </p>
                <div className="flex space-x-4">
                  <a
                    href={assignment.fileLink}
                    download
                    className="px-4 py-2 bg-indigo-500 text-white rounded-lg shadow-sm hover:bg-indigo-600 transition-colors duration-300"
                  >
                    Unduh PDF
                  </a>
                  <button
                    onClick={() => setShowInput(index)}
                    className="px-4 py-2 bg-green-500 text-white rounded-lg shadow-sm hover:bg-green-600 transition-colors duration-300"
                  >
                    Kerjakan
                  </button>
                </div>
                {showInput === index && (
                  <div className="mt-4">
                    <input
                      type="text"
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                      placeholder="Masukkan link tugas"
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
                    />
                    <button
                      onClick={handleSubmit}
                      className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg shadow-sm hover:bg-blue-600 transition-colors duration-300"
                    >
                      Kirim
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {/* Nilai Tugas List */}
        {activeCategory === "nilai" && (
          <div className="space-y-6">
            {gradedAssignments.map((gradedAssignment, index) => (
              <div
                key={index}
                className="bg-white p-6 border border-gray-300 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300"
              >
                <h2 className="text-2xl font-semibold text-indigo-700 mb-4">
                  {gradedAssignment.name}
                </h2>
                <p className="text-gray-800 mb-2">
                  Tanggal Pengumpulan:{" "}
                  <span className="font-medium">
                    {gradedAssignment.dueDate}
                  </span>
                </p>
                <p className="text-gray-800 mb-2">
                  Nilai:{" "}
                  <span className="font-medium">{gradedAssignment.grade}</span>
                </p>
                <p className="text-gray-600">{gradedAssignment.description}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignmentRoom;
