import { createBrowserRouter } from "react-router-dom";
import NotFoundPage from "../Components/notFound";
import ErrorPage from "../Components/pageError";
import Dashboard from "./Dashboard";
import HomePage from "../Pages/HomePage";
import ProfilePage from "../Pages/ProfilePage";
import MessagesPage from "../Pages/MessagesPage";
import WorkPage from "../Pages/WorkPage";
import DetailWork from "../Pages/WorkPage/DetailWork.tsx";
import NotificationPage from "../Pages/NotificationPage";
import SearchPage from "../Pages/SearchPage.tsx";
import LessonTimetablePage from "../Pages/student/lessonTimetablePage";
import GoingClass from "../Pages/student/lessonTimetablePage/goingClass";
import MaterialSpace from "../Pages/student/lessonTimetablePage/materialSpace";
import AssignmentRoom from "../Pages/student/lessonTimetablePage/assignmentRoom";
import AcademicCalendar from "../Pages/academicCalendar";
import Teaching from "../Pages/Teaching";
import InternshipForm from "../Pages/WorkPage/InternshipForm";
import ListBooks from "../Pages/ListBooks";
import StatusBorrow from "../Pages/ListBooks/statusBorrow";
import ExamList from "../Pages/student/examList";
import Essay from "../Pages/student/examList/essay";
import MarkUts from "../Pages/student/MarkUts";
import MarkFinalScore from "../Pages/student/finalScore";
import SemesterGrades from "../Pages/student/semesterGrades";
import Consolidation from "../Pages/student/consolidation";
import DetailArtikel from "../Pages/student/consolidation/ConsolidationArtikel/detailArtikel";
import FormLogin from "../Pages/formLogin";
import DetailEvent from "../Pages/SearchPage.tsx/eventResult/detailEvent";
import FormRegistrasiEvent from "../Pages/SearchPage.tsx/eventResult/FormRegisterEvent";
import DetailSchool from "../Pages/SearchPage.tsx/SchoolResult/detailSchool";
import DetailExtrakulikuler from "../Pages/Exrakulikuler/detailExtrakulikuler";
import ProgramSchool from "../Pages/programSchool";
import DetailProgramSchool from "../Pages/programSchool/detailProgramSchool";
import DetailCompany from "../Pages/SearchPage.tsx/companyResult/detailCompany";
import RoleSection from "../Pages/roleSection";
import DailyValue from "../Pages/teacher/ValueStudent/dailyValue";

const handleClose = () => {
  console.log("Pesan ditutup");
};
export const Routers = createBrowserRouter([
  {
    path: "/login",
    element: <FormLogin />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/role",
    element: <RoleSection />,
    errorElement: <ErrorPage />,
  },

  // siswa
  {
    path: "/",
    element: <Dashboard />,
    errorElement: <NotFoundPage />,
    children: [
      { index: true, element: <HomePage />, errorElement: <ErrorPage /> },
      {
        path: "/homepage",
        element: <HomePage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/profile",
        element: <ProfilePage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/messages",
        element: <MessagesPage onClose={handleClose} />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/internship",
        element: <WorkPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/internship/detail",
        element: <DetailWork />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/internship/form",
        element: <InternshipForm />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/notification",
        element: <NotificationPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/search",
        element: <SearchPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/siswa",
        errorElement: <ErrorPage />,
        children: [
          {
            path: "jadwal-pelajaran",
            element: <LessonTimetablePage />,
            errorElement: <ErrorPage />,
          },
          {
            path: "jadwal-pelajaran/absen",
            element: <GoingClass />,
            errorElement: <ErrorPage />,
          },
          {
            path: "jadwal-pelajaran/ruang-materi",
            element: <MaterialSpace />,
            errorElement: <ErrorPage />,
          },
          {
            path: "jadwal-pelajaran/tugas",
            element: <AssignmentRoom />,
            errorElement: <ErrorPage />,
          },

          {
            path: "mengajar",
            element: <Teaching />,
            errorElement: <ErrorPage />,
          },
          {
            path: "nilai-uts",
            element: <MarkUts />,
            errorElement: <ErrorPage />,
          },
          {
            path: "nilai-uas",
            element: <MarkFinalScore />,
            errorElement: <ErrorPage />,
          },
          {
            path: "nilai-semester",
            element: <SemesterGrades />,
            errorElement: <ErrorPage />,
          },
          {
            path: "list-buku",
            element: <ListBooks />,
            errorElement: <ErrorPage />,
          },
          {
            path: "status-pinjaman",
            element: <StatusBorrow />,
            errorElement: <ErrorPage />,
          },
          {
            path: "daftar-ujian",
            element: <ExamList />,
            errorElement: <ErrorPage />,
          },
          {
            path: "ujian",
            element: <Essay />,
            errorElement: <ErrorPage />,
          },

          {
            path: "konseling",
            element: <Consolidation />,
            errorElement: <ErrorPage />,
          },
          {
            path: "konseling/detail-artikel",
            element: <DetailArtikel />,
            errorElement: <ErrorPage />,
          },
          {
            path: "calender",
            element: <AcademicCalendar />,
            errorElement: <ErrorPage />,
          },
        ],
      },

      {
        path: "/detail-event",
        element: <DetailEvent />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/register-event",
        element: <FormRegistrasiEvent />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/detail-sekolah",
        element: <DetailSchool />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/detail-ekstrakulikuler",
        element: <DetailExtrakulikuler />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/program-sekolah",
        element: <ProgramSchool />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/detail-program-sekolah",
        element: <DetailProgramSchool />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/detail-perusahaan",
        element: <DetailCompany />,
        errorElement: <ErrorPage />,
      },

      // Guru
      {
        path: "/guru",
        element: <NotFoundPage />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <NotFoundPage />,
            errorElement: <ErrorPage />,
          },
          {
            path: "penilaian-harian",
            element: <DailyValue />,
            errorElement: <ErrorPage />,
          },
        ],
      },
    ],
  },
]);
