import React, { useState } from "react";
import { ImageProfil } from "../../Assets/Images";
import { IconSearch } from "../../Components/Navbar/Icons";
import Chatting from "./Chatting";
import { IconClose } from "../../Assets/Icons";

interface Chat {
  id: number;
  name: string;
  lastMessage: string;
  time: string;
  category: "pesan" | "kelas" | "komunitas";
}

const mockChats: Chat[] = [
  {
    id: 1,
    name: "Math Group",
    lastMessage: "Can we discuss the assignment?",
    time: "12:30 PM",
    category: "kelas",
  },
  {
    id: 2,
    name: "Science Club",
    lastMessage: "The project is due next week!",
    time: "11:15 AM",
    category: "komunitas",
  },
  {
    id: 3,
    name: "History Class",
    lastMessage: "Don’t forget to read Chapter 4.",
    time: "10:45 AM",
    category: "kelas",
  },
  {
    id: 4,
    name: "John Doe",
    lastMessage: "How are you?",
    time: "9:30 AM",
    category: "pesan",
  },
  {
    id: 5,
    name: "Jane Smith",
    lastMessage: "See you tomorrow!",
    time: "8:00 AM",
    category: "pesan",
  },
  {
    id: 6,
    name: "Community Group",
    lastMessage: "Meeting is at 5 PM.",
    time: "7:00 AM",
    category: "komunitas",
  },
];

interface MessagesPageProps {
  onClose: () => void;
}
const MessagesPage: React.FC<MessagesPageProps> = ({ onClose }) => {
  const [showSearch, setShowSearch] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [activeCategory, setActiveCategory] = useState<
    "pesan" | "kelas" | "komunitas"
  >("pesan");

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };

  const toggleChat = (id: number) => {
    setShowChat(true);
  };

  const handleCloseChat = () => {
    setShowChat(false);
  };

  const filteredChats = mockChats.filter(
    (chat) => chat.category === activeCategory
  );

  return (
    <div className="h-full font-inter pt-[20px] lg:bottom-0  flex flex-col justify-center sm:py-12">
      <div className="relative sm:max-w-xl sm:mx-auto">
        <div className="relative bg-slate-100 border-slate-500 border-sl shadow-lg lg:w-[350px] sm:rounded-xl sm:p-20 lg:p-5">
          <div className=" max-w-md mx-auto">
            <div className="hidden lg:block">
              <div className="flex justify-between">
                <div className="flex gap-2 items-center">
                  <div className="w-[30px] h-[30px]">
                    <img
                      src={"https://picsum.photos/200/300?random=2"}
                      alt=""
                      className="w-full h-full object-cover rounded-full"
                    />
                  </div>
                  <p>Abdul Majid</p>
                </div>
                <button onClick={onClose}>
                  {IconClose("24", "24", "gray")}
                </button>
              </div>
            </div>
            <div className="fixed flex justify-between border-b border-slate-200 px-4 w-full lg:w-[300px] lg:mt-[5px] lg:py-1 py-[10px]">
              <div className="flex lg:text-[12px] gap-[20px] text-[18px] font-semibold">
                <p
                  onClick={() => setActiveCategory("pesan")}
                  className={`cursor-pointer ${
                    activeCategory === "pesan" ? "text-blue-500" : ""
                  }`}
                >
                  Pesan
                </p>
                <p
                  onClick={() => setActiveCategory("kelas")}
                  className={`cursor-pointer ${
                    activeCategory === "kelas" ? "text-blue-500" : ""
                  }`}
                >
                  Kelas
                </p>
                <p
                  onClick={() => setActiveCategory("komunitas")}
                  className={`cursor-pointer ${
                    activeCategory === "komunitas" ? "text-blue-500" : ""
                  }`}
                >
                  Komunitas
                </p>
              </div>
              <div onClick={toggleSearch} className="lg:hidden mr-[10px]">
                {IconSearch("24", "24", "gray")}
              </div>
              <div onClick={toggleSearch} className="hidden lg:block mr-[10px]">
                {IconSearch("18", "18", "gray")}
              </div>
            </div>
            {showSearch && (
              <div className="flex absolute top-0 left-0 right-0 mt-[47px] rounded-b-lg bg-gradient-to-r from-blue-50 to-white py-[10px] px-5 shadow-lg items-center">
                <div className="flex-grow">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="outline-none border border-gray-300 rounded-l-full px-4 w-full h-[40px] transition ease-in-out duration-300 focus:border-blue-400 focus:ring-1 focus:ring-blue-400"
                  />
                </div>
                <div>
                  <button className="px-4 lg:hidden py-2 h-[40px] bg-blue-500 text-white rounded-r-full shadow-md hover:bg-blue-600 transition ease-in-out duration-300 flex items-center justify-center">
                    {IconSearch("24", "24", "white")}
                  </button>
                  <button className="px-4 lg:block hidden py-2 h-[40px] bg-blue-500 text-white rounded-r-full shadow-md hover:bg-blue-600 transition ease-in-out duration-300 ">
                    {IconSearch("10", "10", "white")}
                  </button>
                </div>
              </div>
            )}
            <div className=" px-4 pt-[50px] lg:pt-[28px]  lg:h-[350px]   ">
              {filteredChats.map((chat) => (
                <div
                  onClick={() => toggleChat(chat.id)}
                  key={chat.id}
                  className="py-4 lg:py-2 border-gray-200 border-b flex gap-4 items-center "
                >
                  <div className="w-[50px] h-[50px] lg:h-[30px] lg:w-[30px]">
                    <img
                      src={"https://picsum.photos/200/300?random=4"}
                      alt=""
                      className="w-full h-full object-cover rounded-full"
                    />
                  </div>
                  <div className="flex-grow font-inter ">
                    <p className="text-lg lg:text-[12px] font-semibold text-gray-800">
                      {chat.name}
                    </p>
                    <p className="text-gray-600 text-sm lg:text-[10px]">
                      {chat.lastMessage}
                    </p>
                  </div>
                  <span className="text-gray-500 text-xs lg:text-[8px]">
                    {chat.time}
                  </span>
                </div>
              ))}
            </div>
            <div className="absolute top-0 w-full lg:right-[360px]">
              {showChat && <Chatting onClose={handleCloseChat} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesPage;
