import React, { useState, useEffect } from "react";

// Data soal
interface Question {
  id: number;
  type: "multiple-choice" | "essay";
  questionText: string;
  image?: string;
  options?: string[];
}

const questions: Question[] = [
  {
    id: 1,
    type: "multiple-choice",
    questionText: "Apa warna bendera negara Indonesia?",
    options: ["Merah-Putih", "Biru-Putih", "Merah-Kuning", "Hijau-Kuning"],
  },
  {
    id: 2,
    type: "multiple-choice",
    questionText: "Hewan apa yang menjadi lambang negara Indonesia?",
    image: "https://picsum.photos/200/300?random=7",
    options: ["Harimau", "Garuda", "Singa", "Kijang"],
  },
  {
    id: 3,
    type: "multiple-choice",
    questionText: "Apa ibu kota negara Indonesia?",
    options: ["Jakarta", "Surabaya", "Bandung", "Medan"],
  },
  // Tambahkan soal lainnya sesuai kebutuhan
  {
    id: 11,
    type: "essay",
    questionText: "Jelaskan proses fotosintesis pada tumbuhan.",
  },
  {
    id: 12,
    type: "essay",
    questionText: "Apa itu hukum Newton pertama?",
  },
  // Tambahkan soal essay lainnya
];

const Essay: React.FC = () => {
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [answers, setAnswers] = useState<{ [key: number]: string }>({});
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [copyWarning, setCopyWarning] = useState<boolean>(false);

  // Fungsi untuk mendeteksi copy action
  useEffect(() => {
    const handleCopy = () => {
      setCopyWarning(true);
      setTimeout(() => {
        setCopyWarning(false);
      }, 3000);
    };

    document.addEventListener("copy", handleCopy);
    return () => {
      document.removeEventListener("copy", handleCopy);
    };
  }, []);

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handlePrev = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleAnswerChange = (id: number, value: string) => {
    setAnswers({
      ...answers,
      [id]: value,
    });
  };

  const isAllAnswered = questions.every((q) => answers[q.id]);

  const isLastQuestion = currentQuestion === questions.length - 1;

  const handleFinish = () => {
    alert("Ujian selesai! Terima kasih sudah mengerjakan.");
  };

  const renderQuestion = () => {
    const question = questions[currentQuestion];
    if (question.type === "multiple-choice") {
      return (
        <div>
          <h3 className="font-semibold mb-2">{question.questionText}</h3>
          {question.image && (
            <img src={question.image} alt="Question" className="mb-2" />
          )}
          <ul className="space-y-2">
            {question.options?.map((option, index) => (
              <li key={index}>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name={`question-${question.id}`}
                    value={option}
                    onChange={(e) =>
                      handleAnswerChange(question.id, e.target.value)
                    }
                    checked={answers[question.id] === option}
                    className="form-radio text-blue-600"
                  />
                  <span className="ml-2">{option}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      );
    } else if (question.type === "essay") {
      return (
        <div>
          <h3 className="font-semibold mb-2">{question.questionText}</h3>
          <textarea
            rows={5}
            className="w-full border border-gray-300 rounded-lg p-2"
            value={answers[question.id] || ""}
            onChange={(e) => handleAnswerChange(question.id, e.target.value)}
          />
        </div>
      );
    }
  };

  return (
    <div className="p-5 font-inter">
      <h1 className="text-2xl font-bold mb-4">Ujian Sekolah</h1>

      {/* Peringatan teks disalin */}
      {copyWarning && (
        <div className="mb-4 p-2 bg-yellow-200 text-yellow-800 rounded-lg">
          Peringatan: Bersikaplah jujur agar bisa mencerminkan anak bangsa!
        </div>
      )}

      {/* Dropdown button */}
      <div className="relative inline-block text-right mb-4 w-full">
        <button
          className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          Pilih Soal {currentQuestion + 1} <span className="ml-2">▼</span>
        </button>
        {dropdownOpen && (
          <div className="absolute z-10 mt-2 w-full bg-white border p-1 border-gray-300 rounded-lg shadow-lg">
            <div className="grid grid-cols-5 gap-1">
              {questions.map((question, index) => (
                <div
                  key={index}
                  className={`px-2 py-2 text-center border rounded-lg ${
                    answers[question.id]
                      ? "bg-blue-800 text-white"
                      : "hover:bg-gray-100 cursor-pointer"
                  } ${
                    index === currentQuestion ? "bg-green-400 text-black" : ""
                  }`}
                  onClick={() => {
                    setCurrentQuestion(index);
                    setDropdownOpen(false);
                  }}
                >
                  {index + 1}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="mb-4">
        <span>
          Soal {currentQuestion + 1} dari {questions.length}
        </span>
      </div>

      <div className="mb-8">{renderQuestion()}</div>

      <div className="flex justify-between">
        <button
          className={`px-4 py-2 rounded-lg bg-gray-300 ${
            currentQuestion === 0 ? "cursor-not-allowed" : ""
          }`}
          onClick={handlePrev}
          disabled={currentQuestion === 0}
        >
          Sebelumnya
        </button>

        {isLastQuestion && isAllAnswered ? (
          <button
            className="px-4 py-2 rounded-lg bg-blue-500 text-white"
            onClick={handleFinish}
          >
            Selesai
          </button>
        ) : (
          <button
            className={`px-4 py-2 rounded-lg bg-blue-500 text-white ${
              isLastQuestion ? "cursor-not-allowed" : ""
            }`}
            onClick={handleNext}
            disabled={isLastQuestion}
          >
            Selanjutnya
          </button>
        )}
      </div>
    </div>
  );
};

export default Essay;
