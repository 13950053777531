import React, { useState } from "react";
import PendidikanProfile from "./PendidikanProfile";
import PengalamanProfile from "./PengalamanProfile";
import SkillProfile from "./SkillProfile";
import PostProfile from "./PostProfile";

const ProfilePage = () => {
  const [activeTab, setActiveTab] = useState("post");

  const renderActiveComponent = () => {
    switch (activeTab) {
      case "pendidikan":
        return <PendidikanProfile />;
      case "pengalaman":
        return <PengalamanProfile />;
      case "skill":
        return <SkillProfile />;
      case "post":
        return <PostProfile />;
      default:
        return null;
    }
  };

  return (
    <main>
      <section>
        <div className="w-full h-[100px]">
          <img
            src={"https://picsum.photos/200/300?random=3"}
            alt="Background"
            className="h-full w-full object-cover"
          />
        </div>
        <div className="flex gap-4">
          <div className="w-[100px] mt-[-35px] h-[100px] ml-[20px]">
            <img
              src={"https://picsum.photos/200/300?random=8"}
              alt="Profile"
              className="w-full h-full object-cover rounded-full"
            />
          </div>
          <div className="flex items-center gap-4 text-[24px] font-bold">
            <h1>Abdul Majid</h1>
          </div>
        </div>
        <div className="mx-[30px] mt-[10px]">
          <p className="text-[14px]">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa saepe
            eveniet nobis enim obcaecati? Commodi modi fugit reprehenderit ad
            veniam?
          </p>
        </div>
        {/* pada bagian ini lebarnya samakan */}
        <div className="overflow-x-auto mt-[30px] mx-[20px]">
          <div className="flex gap-2">
            <p
              className={`${
                activeTab === "post"
                  ? "bg-blue-800 text-white"
                  : "border border-blue-800 text-blue-800"
              } shadow rounded-lg py-[5px] px-[10px] cursor-pointer`}
              onClick={() => setActiveTab("post")}
            >
              Postingan
            </p>
            <p
              className={`${
                activeTab === "pendidikan"
                  ? "bg-blue-800 text-white"
                  : "border border-blue-800 text-blue-800"
              } shadow rounded-lg py-[5px] px-[10px] cursor-pointer`}
              onClick={() => setActiveTab("pendidikan")}
            >
              Pendidikan
            </p>
            <p
              className={`${
                activeTab === "pengalaman"
                  ? "bg-blue-800 text-white"
                  : "border border-blue-800 text-blue-800"
              } shadow rounded-lg py-[5px] px-[10px] cursor-pointer`}
              onClick={() => setActiveTab("pengalaman")}
            >
              Pengalaman
            </p>
            <p
              className={`${
                activeTab === "skill"
                  ? "bg-blue-800 text-white"
                  : "border border-blue-800 text-blue-800"
              } shadow rounded-lg py-[5px] px-[40px]  cursor-pointer`}
              onClick={() => setActiveTab("skill")}
            >
              Skill
            </p>
            {/* Tambahkan lebih banyak tab jika diperlukan */}
          </div>
        </div>
        <div className="mt-[20px] mx-[20px]">{renderActiveComponent()}</div>
      </section>
    </main>
  );
};

export default ProfilePage;
