// SearchPage.tsx
import React, { useState } from "react";
import PostinganResults from "./postinganResult";
import OrangResults from "./orangResult";
import EventResults from "./eventResult";
import SchoolResults from "./SchoolResult";
import CompanyResults from "./companyResult";
import Post from "../../Components/post";

export interface SearchResult {
  id: number;
  title: string;
  description: string;
  category: "postingan" | "orang" | "event" | "sekolah" | "perusahaan";
  imageUrl: string;
  timestamp?: string;
}

// Data statis dengan gambar dan waktu postingan
const staticData: SearchResult[] = [
  {
    id: 1,
    title: "Postingan Terbaru",
    description: "Deskripsi tentang postingan terbaru",
    category: "postingan",
    imageUrl: "https://via.placeholder.com/150",
    timestamp: "2024-08-25T10:00:00Z",
  },
  {
    id: 2,
    title: "Profil Orang A",
    description: "Deskripsi tentang orang A",
    category: "orang",
    imageUrl: "https://via.placeholder.com/150",
  },
  {
    id: 3,
    title: "Event Besar 2024",
    description: "Deskripsi tentang event besar 2024",
    category: "event",
    imageUrl: "https://via.placeholder.com/150",
    timestamp: "2024-08-20T15:30:00Z",
  },
  {
    id: 4,
    title: "sekolah di Perusahaan X",
    description: "Deskripsi tentang sekolah di perusahaan X",
    category: "sekolah",
    imageUrl: "https://via.placeholder.com/150",
    timestamp: "2024-08-15T12:00:00Z",
  },
  {
    id: 5,
    title: "Perusahaan Terbesar",
    description: "Deskripsi tentang perusahaan terbesar",
    category: "perusahaan",
    imageUrl: "https://via.placeholder.com/150",
    timestamp: "2024-08-10T08:00:00Z",
  },
  {
    id: 6,
    title: "Postingan Lain",
    description: "Deskripsi tentang postingan lain",
    category: "postingan",
    imageUrl: "https://via.placeholder.com/150",
    timestamp: "2024-08-05T09:00:00Z",
  },
  {
    id: 7,
    title: "Profil Orang B",
    description: "Deskripsi tentang orang B",
    category: "orang",
    imageUrl: "https://via.placeholder.com/150",
  },
  {
    id: 8,
    title: "Event Menarik",
    description: "Deskripsi tentang event menarik",
    category: "event",
    imageUrl: "https://via.placeholder.com/150",
    timestamp: "2024-08-01T14:00:00Z",
  },
  {
    id: 9,
    title: "sekolah di Perusahaan Y",
    description: "Deskripsi tentang sekolah di perusahaan Y",
    category: "sekolah",
    imageUrl: "https://via.placeholder.com/150",
    timestamp: "2024-07-25T11:00:00Z",
  },
  {
    id: 10,
    title: "Perusahaan Baru",
    description: "Deskripsi tentang perusahaan baru",
    category: "perusahaan",
    imageUrl: "https://via.placeholder.com/150",
    timestamp: "2024-07-20T07:00:00Z",
  },
];

const SearchPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<
    "postingan" | "orang" | "event" | "sekolah" | "perusahaan"
  >("postingan");

  const filteredResults = staticData
    .filter((result) =>
      result.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((result) => result.category === selectedCategory);

  const renderResults = () => {
    switch (selectedCategory) {
      case "postingan":
        return <Post />;
      case "orang":
        return <OrangResults />;
      case "event":
        return <EventResults />;
      case "sekolah":
        return <SchoolResults />;
      case "perusahaan":
        return <CompanyResults />;
      default:
        return <p>No results found.</p>;
    }
  };

  return (
    <div className="container font-inter mx-auto p-4 lg:p-0">
      <div className="fixed border-b shadow lg:w-[770px] border-slate-300 px-4 lg:mt-[-15px] py-2 flex justify-between bg-white w-full">
        <h1 className="text-[18px] font-bold   text-gray-800">Pencarian</h1>
      </div>
      <div className="mb-4 lg:hidden">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Cari..."
          className="px-4 py-2 border rounded w-full shadow-sm"
        />
      </div>

      <div className="mb-4 overflow-x-auto lg:pt-[40px] lg:px-4">
        <div className="flex space-x-2">
          {["postingan", "orang", "event", "sekolah", "perusahaan"].map(
            (category) => (
              <button
                key={category}
                className={`px-4 py-2 rounded-lg ${
                  selectedCategory === category
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200 text-gray-800"
                } hover:bg-blue-500 transition`}
                onClick={() =>
                  setSelectedCategory(
                    category as
                      | "postingan"
                      | "orang"
                      | "event"
                      | "sekolah"
                      | "perusahaan"
                  )
                }
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            )
          )}
        </div>
      </div>

      <div className="px-[30px]">{renderResults()}</div>
    </div>
  );
};

export default SearchPage;
