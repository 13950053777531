import React from "react";
import TruncateText from "../../../../../../Components/truncateText";

interface Artikel {
  image: string;
  title: string;
  releaseDate: string;
  description: string;
}

const artikelList: Artikel[] = [
  {
    image: "https://picsum.photos/200/300?random=1", // Ganti dengan URL gambar artikel terkait
    title: "Manfaat Olahraga Pagi",
    releaseDate: "August 20, 2024",
    description:
      "Artikel ini membahas manfaat olahraga pagi untuk kesehatan secara lebih rinci.",
  },
  {
    image: "https://picsum.photos/200/300?random=2", // Ganti dengan URL gambar artikel terkait
    title: "Panduan untuk memulai rutinitas pagi yang efektif dan menyegarkan",
    releaseDate: "August 15, 2024",
    description:
      "Panduan untuk memulai rutinitas pagi yang efektif dan menyegarkan.Panduan untuk memulai rutinitas pagi yang efektif dan menyegarkan.Panduan untuk memulai rutinitas pagi yang efektif dan menyegarkan.Panduan untuk memulai rutinitas pagi yang efektif dan menyegarkan.Panduan untuk memulai rutinitas pagi yang efektif dan menyegarkan.Panduan untuk memulai rutinitas pagi yang efektif dan menyegarkan.Panduan untuk memulai rutinitas pagi yang efektif dan menyegarkan.",
  },
  {
    image: "https://picsum.photos/200/300?random=3", // Ganti dengan URL gambar artikel terkait
    title: "Tips Diet Sehat",
    releaseDate: "August 10, 2024",
    description:
      "Tips dan trik untuk menjaga pola makan sehat dalam keseharian.",
  },
];

const ArtikelMore = () => {
  return (
    <div className="max-w-6xl font-inter mx-auto p-4">
      <h2 className="text-3xl font-semibold mb-6">Artikel Terkait</h2>
      <div className="grid grid-cols-2  lg:grid-cols-1 gap-2 lg:gap-4 lg:w-[300px]">
        {artikelList.map((artikel, index) => (
          <div
            key={index}
            className="bg-white shadow-md rounded-lg overflow-hidden"
          >
            <div className="h-[120px]">
              <img
                src={artikel.image}
                alt={artikel.title}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="p-2">
              <p className="text-gray-600 text-[12px]">{artikel.releaseDate}</p>
              <h3 className="text-[14px] h-[40px] font-semibold mt-1 leading-[17px]">
                {TruncateText(artikel.title, 32)}
              </h3>
              <p className="text-gray-700 text-[12px]">
                {TruncateText(artikel.description, 100)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArtikelMore;
