import React from "react";
import TruncateText from "../../Components/truncateText";
import { Link } from "react-router-dom";

interface SchoolProgram {
  id: number;
  image: string;
  title: string;
  description: string;
  schedule: string;
}

const programs: SchoolProgram[] = [
  {
    id: 1,
    image: "https://via.placeholder.com/400x200?text=Seni",
    title: "Program Keterampilan Seni",
    description:
      "Program ini berfokus pada pengembangan keterampilan seni, termasuk lukisan, patung, dan seni grafis. Cocok untuk siswa yang tertarik dengan seni visual.",
    schedule: "Selasa dan Kamis, 14:00 - 16:00",
  },
  {
    id: 2,
    image: "https://via.placeholder.com/400x200?text=Teknologi",
    title: "Program Teknologi dan Robotika",
    description:
      "Program ini mengajarkan dasar-dasar teknologi dan robotika. Siswa akan belajar tentang pemrograman, elektronik, dan pengembangan robot.",
    schedule: "Senin dan Rabu, 15:00 - 17:00",
  },
  {
    id: 3,
    image: "https://via.placeholder.com/400x200?text=Olahraga",
    title: "Program Olahraga dan Kebugaran",
    description:
      "Program ini dirancang untuk meningkatkan kebugaran dan keterampilan olahraga siswa melalui berbagai kegiatan fisik dan pelatihan.",
    schedule: "Jumat, 16:00 - 18:00",
  },
  {
    id: 4,
    image: "https://via.placeholder.com/400x200?text=Bahasa",
    title: "Program Bahasa dan Sastra",
    description:
      "Program ini memperdalam pengetahuan tentang bahasa dan sastra, dengan fokus pada membaca, menulis, dan analisis teks.",
    schedule: "Rabu dan Jumat, 14:00 - 16:00",
  },
];

const ProgramSchool: React.FC = () => {
  return (
    <div className="max-w-4xl font-inter mx-auto">
      <div className="space-y-6">
        {programs.map((program) => (
          <div
            key={program.id}
            className="bg-white rounded-lg shadow-md hover:bg-gray-100 transition-colors duration-300"
          >
            <img
              src={program.image}
              alt={program.title}
              className="w-full h-48 object-cover rounded-t-lg"
            />
            <Link to={"/detail-program-sekolah"}>
              <div className="p-2">
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                  {program.title}
                </h2>
                <p className="text-gray-600 mb-2">
                  {TruncateText(program.description, 50)}
                </p>
                <p className="text-gray-500">
                  <strong>Jadwal:</strong> {program.schedule}
                </p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgramSchool;
