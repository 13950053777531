import React from "react";
import { Link } from "react-router-dom";

interface Extracurricular {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
}

const extracurricularData: Extracurricular[] = [
  {
    id: 1,
    name: "Basketball Club",
    description: "Latihan untuk menjadi pemain basket profesional.",
    imageUrl: "https://picsum.photos/200/300?random=",
  },
  {
    id: 2,
    name: "Music Club",
    description: "Pelajari alat musik dan bergabung dengan band sekolah.",
    imageUrl: "https://picsum.photos/200/300?random=2",
  },
  {
    id: 3,
    name: "Art Club",
    description: "Ekspresikan kreativitasmu melalui seni lukis dan kerajinan.",
    imageUrl: "https://picsum.photos/200/300?random=3",
  },
  {
    id: 4,
    name: "Science Club",
    description:
      "Eksperimen dan belajar ilmu pengetahuan dengan cara yang menyenangkan.",
    imageUrl: "https://picsum.photos/200/300?random=4",
  },
];

const Extrakulikuler: React.FC = () => {
  return (
    <div className="py-5 font-inter">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 px-5 sm:px-10">
        {extracurricularData.map((activity) => (
          <div
            key={activity.id}
            className="bg-white shadow-xl rounded-lg overflow-hidden transform hover:scale-110 hover:shadow-2xl transition-all duration-500 ease-in-out"
          >
            <div className="relative">
              <img
                src={activity.imageUrl}
                alt={activity.name}
                className="w-full h-48 object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-75"></div>
              <h2 className="absolute bottom-4 left-4 text-2xl font-bold text-white">
                {activity.name}
              </h2>
            </div>
            <div className="p-4">
              <p className="text-gray-700 text-[13px] mb-4">
                {activity.description}
              </p>

              <div className="text-center">
                <Link to={"/detail-ekstrakulikuler"}>
                  <button className="bg-gradient-to-r from-purple-500 to-blue-500 text-white px-6 py-2 rounded-full hover:from-blue-500 hover:to-purple-500 transition-all duration-300">
                    Bergabung
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Extrakulikuler;
