import React from "react";
import Slider from "react-slick";

interface ExtracurricularDetail {
  title: string;
  description: string;
  schedule: string;
  contact: string;
  images: string[];
  achievements: string[];
}

const data: ExtracurricularDetail = {
  title: "Klub Musik",
  description:
    "Klub Musik adalah tempat yang ideal bagi para pecinta musik untuk berkumpul, berlatih, dan tampil. Kami menyediakan berbagai alat musik dan pelatihan oleh para ahli di bidangnya.",
  schedule: "Senin dan Rabu, 15:00 - 17:00",
  contact: "email@klubmusik.com",
  images: [
    "https://picsum.photos/200/300?random=1",
    "https://picsum.photos/200/300?random=2",
    "https://picsum.photos/200/300?random=3",
  ],
  achievements: [
    "Juara 1 Kompetisi Band Sekolah 2023",
    "Best Performance dalam Festival Musik 2022",
    "Penghargaan untuk Inovasi Musik 2021",
  ],
};

const DetailExtrakulikuler: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="max-w-4xl mx-auto p-6 font-inter ">
      <div className=" mb-[20px] flex gap-[20px] items-center">
        <div className="w-[70px] h-[70px]">
          <img
            src="https://picsum.photos/200/300?random=4"
            alt=""
            className="w-full h-full rounded-full object-cover"
          />
        </div>
        <div className="font-roboto">
          <p className="text-[16px] font-semibold">SMAN 1 Pedes Karawang</p>
          <h1 className="text-[16px] font-bold text-gray-600 ">{data.title}</h1>
        </div>
      </div>

      {/* Image Slider */}
      <div className="mb-8">
        <Slider {...settings}>
          {data.images.map((image, index) => (
            <div key={index} className="relative group">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-64 object-cover rounded-lg shadow-lg transition-transform duration-500 ease-in-out transform group-hover:scale-105"
              />
            </div>
          ))}
        </Slider>
      </div>

      <div className="bg-white p-4 rounded-lg shadow-md">
        <p className="text-gray-700 text-lg mb-4">{data.description}</p>
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">Jadwal:</h2>
          <p className="text-gray-600">{data.schedule}</p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">Kontak:</h2>
          <p className="text-gray-600">{data.contact}</p>
        </div>

        {/* Achievements */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Prestasi:
          </h2>
          <ul className="list-disc list-inside text-gray-600 space-y-2">
            {data.achievements.map((achievement, index) => (
              <li
                key={index}
                className="transition-transform duration-300 ease-in-out transform hover:scale-105 hover:text-gray-800"
              >
                {achievement}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DetailExtrakulikuler;
