import React, { useState } from "react";

interface InternshipFormData {
  image: File | null;
  imagePreviewUrl: string | null;
  fullName: string;
  cv: File | null;
  cvPreviewUrl: string | null;
  resume: string;
  transcript: File | null;
  transcriptPreviewUrl: string | null;
  recommendationLetter: File | null;
  recommendationLetterPreviewUrl: string | null;
  organizationalExperience: string;
  skills: string;
}

const InternshipForm: React.FC = () => {
  const [formData, setFormData] = useState<InternshipFormData>({
    image: null,
    imagePreviewUrl: null,
    fullName: "",
    cv: null,
    cvPreviewUrl: null,
    resume: "",
    transcript: null,
    transcriptPreviewUrl: null,
    recommendationLetter: null,
    recommendationLetterPreviewUrl: null,
    organizationalExperience: "",
    skills: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (e.target instanceof HTMLInputElement && e.target.type === "file") {
      const fileInput = e.target as HTMLInputElement;
      const file = fileInput.files ? fileInput.files[0] : null;
      const previewUrl = file ? URL.createObjectURL(file) : null;

      setFormData((prevState) => ({
        ...prevState,
        [name]: file,
        [`${name}PreviewUrl`]: previewUrl,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Lakukan submit data di sini, misalnya mengirimkan data ke backend
    console.log(formData);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-6 p-6 mt-[30px] bg-white shadow rounded-md"
    >
      <div>
        <label className="block text-sm font-medium text-gray-700">Image</label>
        {formData.imagePreviewUrl && (
          <img
            src={formData.imagePreviewUrl}
            alt="Preview"
            className="mt-2 h-32 w-32 object-cover rounded-full"
          />
        )}
        <input
          type="file"
          name="image"
          accept="image/*"
          onChange={handleInputChange}
          className="mt-1 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Nama Lengkap
        </label>
        <input
          type="text"
          name="fullName"
          value={formData.fullName}
          onChange={handleInputChange}
          className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          CV (PDF)
        </label>
        <input
          type="file"
          name="cv"
          accept="application/pdf"
          onChange={handleInputChange}
          className="mt-1 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Resume
        </label>
        <textarea
          name="resume"
          value={formData.resume}
          onChange={handleInputChange}
          className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Transkip Nilai (PDF)
        </label>
        <input
          type="file"
          name="transcript"
          accept="application/pdf"
          onChange={handleInputChange}
          className="mt-1 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Surat Rekomendasi (PDF)
        </label>
        <input
          type="file"
          name="recommendationLetter"
          accept="application/pdf"
          onChange={handleInputChange}
          className="mt-1 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Pengalaman Organisasi
        </label>
        <textarea
          name="organizationalExperience"
          value={formData.organizationalExperience}
          onChange={handleInputChange}
          className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Keahlian
        </label>
        <textarea
          name="skills"
          value={formData.skills}
          onChange={handleInputChange}
          className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3"
        />
      </div>

      <button
        type="submit"
        className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors"
      >
        Submit
      </button>
    </form>
  );
};

export default InternshipForm;
