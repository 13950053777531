import React from "react";
import { ImageStudent } from "../../../Assets/Images";

interface SkillName {
  id: number;
  nameSkill: string;
  proficiency: string;
}

interface Skill {
  id: number;
  nameLisensi: string;
  image: string;
  deskripsi: string;
  skillName: SkillName[];
}

const skillData: Skill[] = [
  {
    id: 1,
    nameLisensi: "JavaScript Advanced",
    image: ImageStudent,
    deskripsi: "Advanced proficiency in JavaScript programming.",
    skillName: [
      {
        id: 1,
        nameSkill: "JavaScript",
        proficiency: "Advanced",
      },
    ],
  },
  {
    id: 2,
    nameLisensi: "React Intermediate",
    image: ImageStudent,
    deskripsi: "Intermediate proficiency in React framework.",
    skillName: [
      {
        id: 2,
        nameSkill: "React",
        proficiency: "Intermediate",
      },
    ],
  },
];

const proficiencyColors: Record<string, string> = {
  Beginner: "bg-yellow-200 text-yellow-800",
  Intermediate: "bg-green-200 text-green-800",
  Advanced: "bg-blue-200 text-blue-800",
};

const SkillProfile: React.FC = () => {
  return (
    <div className="p-6 bg-white shadow-lg rounded-lg space-y-6">
      {/* Lisensi Section */}
      <div>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Lisensi</h2>
        {skillData.map((item) => (
          <div
            key={item.id}
            className="flex gap-4 items-start border rounded-lg p-4 shadow-md mb-4"
          >
            <img
              src={item.image || ImageStudent}
              alt={item.nameLisensi}
              className="w-16 h-16 object-cover rounded-full"
            />
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-800">
                {item.nameLisensi}
              </h3>
              <p className="text-gray-600 mt-1">{item.deskripsi}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Keahlian Section */}
      <div>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Keahlian</h2>
        <div className="space-y-4">
          {skillData.map((skill, index) => (
            <div
              key={index}
              className="flex flex-col border-b border-gray-200 pb-3"
            >
              {skill.skillName.map((skillItem) => (
                <div
                  key={skillItem.id}
                  className="flex justify-between items-center mb-2"
                >
                  <div>
                    <p className="text-lg font-medium text-gray-700">
                      {skillItem.nameSkill}
                    </p>
                    <p className="text-lg font-medium text-gray-700">
                      {skill.nameLisensi}
                    </p>
                  </div>
                  <span
                    className={`py-1 px-3 rounded-full text-sm ${
                      proficiencyColors[skillItem.proficiency] ||
                      "bg-gray-200 text-gray-500"
                    }`}
                  >
                    {skillItem.proficiency}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SkillProfile;
