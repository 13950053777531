// Teaching.tsx
import React, { useState } from "react";

interface Teacher {
  id: string;
  image: string;
  name: string;
  nip: string;
  schedule: Schedule[];
}

interface Schedule {
  date: string; // Tanggal dalam format YYYY-MM-DD
  kelas: string;
  jam: string; // Jam dalam format HH:MM - HH:MM
}

const teachers: Teacher[] = [
  {
    id: "1",
    image: "https://via.placeholder.com/100",
    name: "John Doe",
    nip: "123456789",
    schedule: [
      { date: "Senin", kelas: "Kelas A", jam: "08:00 - 09:00" },
      { date: "Selasa", kelas: "Kelas B", jam: "10:00 - 11:00" },
    ],
  },
  {
    id: "2",
    image: "https://via.placeholder.com/100",
    name: "Jane Smith",
    nip: "987654321",
    schedule: [
      { date: "Senin", kelas: "Kelas C", jam: "09:00 - 10:00" },
      { date: "Senin", kelas: "Kelas C", jam: "09:00 - 10:00" },
      { date: "Selasa", kelas: "Kelas D", jam: "11:00 - 12:00" },
      { date: "Rabu", kelas: "Kelas C", jam: "09:00 - 10:00" },
      { date: "Kamis", kelas: "Kelas D", jam: "11:00 - 12:00" },
      { date: "Jumat", kelas: "Kelas C", jam: "09:00 - 10:00" },
    ],
  },
];

const Teaching: React.FC = () => {
  const [selectedTeacherId, setSelectedTeacherId] = useState<string | null>(
    null
  );

  const handleTeacherClick = (id: string) => {
    setSelectedTeacherId(selectedTeacherId === id ? null : id);
  };

  return (
    <div className="p-4 font-inter mt-[0px] bg-gray-100 min-h-screen">
      <h1 className="text-[24px] font-extrabold text-gray-900 mb-6">
        Daftar Guru Mengajar
      </h1>
      <div>
        <input
          type="text"
          placeholder="Cari Guru..."
          className="mb-4 p-2 border border-gray-300 rounded-lg w-full"
        />
      </div>
      <div className="space-y-2">
        {teachers.map((teacher) => (
          <div
            key={teacher.id}
            className="bg-white border border-gray-300 rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105"
          >
            <div className="flex items-center p-4 border-b border-gray-200">
              <img
                src={teacher.image}
                alt={teacher.name}
                className="w-20 h-20 rounded-full object-cover border-2 border-blue-500"
              />
              <div className="ml-4">
                <h2 className="text-xl font-semibold text-gray-800">
                  {teacher.name}
                </h2>
                <p className="text-[14px] text-gray-600">NIP: {teacher.nip}</p>
                <button
                  onClick={() => handleTeacherClick(teacher.id)}
                  className={`mt-2 px-6 text-[12px] py-2 rounded-full text-white ${
                    selectedTeacherId === teacher.id
                      ? "bg-red-500"
                      : "bg-blue-500"
                  }  transition`}
                >
                  {selectedTeacherId === teacher.id
                    ? "Sembunyikan"
                    : "Tampilkan "}
                </button>
              </div>
            </div>
            {selectedTeacherId === teacher.id && (
              <div className="p-2">
                <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
                  <thead>
                    <tr className="bg-gray-200 border-b">
                      <th className="px-4 py-2 text-left text-gray-600">
                        Hari
                      </th>
                      <th className="px-4 py-2 text-left text-gray-600">
                        Kelas
                      </th>
                      <th className="px-4 py-2 text-left text-gray-600">Jam</th>
                    </tr>
                  </thead>
                  <tbody>
                    {teacher.schedule.map((item, index) => (
                      <tr
                        key={index}
                        className="border-b text-[14px] hover:bg-gray-50 transition"
                      >
                        <td className="px-4 py-2 text-gray-700">{item.date}</td>
                        <td className="px-4 py-2 text-gray-700">
                          {item.kelas}
                        </td>
                        <td className="px-4 py-2 text-gray-700">{item.jam}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Teaching;
