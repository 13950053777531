import React from "react";
import Post from "../../Components/post";

const HomePage = () => {
  return (
    <div className="lg:w-full">
      <div className="fixed border-b shadow   border-slate-300 px-4 lg:mt-[-15px] py-2 flex justify-between bg-white w-full">
        <h1 className="text-[18px] font-bold   text-gray-800">Beranda</h1>
      </div>
      <div className="lg:pt-[40px] pt-[50px] px-[18px]">
        <Post />
      </div>
    </div>
  );
};

export default HomePage;
