import React from "react";

interface MarkData {
  no: number;
  mapel: string;
  nilai: number;
  kategori: "Wajib" | "Peminatan";
}

const markData: MarkData[] = [
  { no: 1, mapel: "Matematika", nilai: 85, kategori: "Wajib" },
  { no: 2, mapel: "Bahasa Indonesia", nilai: 90, kategori: "Wajib" },
  { no: 3, mapel: "Fisika", nilai: 78, kategori: "Peminatan" },
  { no: 4, mapel: "Kimia", nilai: 88, kategori: "Peminatan" },
  { no: 5, mapel: "Biologi", nilai: 92, kategori: "Peminatan" },
];

// Function to sort data based on category
const sortedMarkData = markData.sort((a, b) => {
  if (a.kategori === b.kategori) return 0;
  return a.kategori === "Wajib" ? -1 : 1;
});

const MarkUts: React.FC = () => {
  // Group data by category
  const categories = Array.from(
    new Set(sortedMarkData.map((mark) => mark.kategori))
  );

  return (
    <div className="container font-inter mx-auto p-6">
      <h1 className="text-3xl font-bold text-center mb-8 text-gray-800">
        Nilai Hasil Ujian Tengah Semester
      </h1>
      <div className="bg-white shadow-lg rounded-lg border border-gray-200 overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          {/* Table Header */}
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="py-3 px-3 text-left text-sm font-medium">No</th>
              <th className="py-3 px-6 text-left text-sm font-medium">
                Mata Pelajaran
              </th>
              <th className="py-3 px-6 text-left text-sm font-medium">Nilai</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {categories.map((kategori) => (
              <React.Fragment key={kategori}>
                {/* Category Header */}
                <tr>
                  <td
                    colSpan={3}
                    className="py-2 px-4 w-[30px] items-center text-[16px] font-semibold bg-gray-100 text-gray-800 border-b border-gray-300"
                  >
                    {kategori}
                  </td>
                </tr>
                {/* Data Rows */}
                {sortedMarkData
                  .filter((mark) => mark.kategori === kategori)
                  .map((mark) => (
                    <tr
                      key={mark.no}
                      className="hover:bg-gray-50 transition-colors duration-300"
                    >
                      <td className="py-3  px-4 w-[30px] text-gray-800 text-sm">
                        {mark.no}
                      </td>
                      <td className="py-3 px-3 text-gray-800 text-sm">
                        {mark.mapel}
                      </td>
                      <td className="py-3 px-6 text-gray-800 text-sm">
                        {mark.nilai}
                      </td>
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex  justify-center mt-[20px] items-center h-full">
        <button className="w-full bg-gradient-to-r from-blue-400 to-blue-600 text-white font-semibold py-2 px-6 rounded-full shadow-lg hover:from-blue-500 hover:to-blue-700 transition ease-in-out duration-300">
          Download
        </button>
      </div>
    </div>
  );
};

export default MarkUts;
