import React, { useState, useRef, useEffect } from "react";
import { IconChevron, IconExit } from "../../Assets/Icons";
import { Link } from "react-router-dom";

interface SidebarProps {
  isOpen: boolean;
  closeMenu: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, closeMenu }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [showProfil, setShowProfil] = useState(false);

  const handleProfil = () => {
    setShowProfil(!showProfil);
  };
  const handleCLoseProfil = () => {
    setShowProfil(false);
  };

  // Function to toggle menu visibility
  const toggleMenu = () => {
    setMenuVisible((prev) => !prev);
  };

  // Close menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuVisible(false);
        setActiveDropdown(null); // Close all dropdowns when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to toggle dropdowns
  const toggleDropdown = (dropdownName: string) => {
    setActiveDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  return (
    <section className="relative font-inter   max-w-[1440px]">
      <div className="flex justify-between bg-red-900 lg:z-80 bg-gradient-to-tr from-blue-900 to-blue-500 shadow-sm py-1 px-4 rounded-b-md">
        <h1 className="text-[20px] lg:text-[25px] lg:font-roboto font-semibold text-white font-libre py-[10px] lg:py-[5px]">
          Student Indonesia
        </h1>

        <div className="relative " onClick={handleProfil}>
          <button className="p-2 z-40 w-[50px] h-[50px] rounded-full outline-none lg:block hidden">
            <img
              src={"https://picsum.photos/200/300?random=2"}
              alt=""
              className="rounded-full w-full h-full object-cover"
            />
          </button>
        </div>
        <button
          onClick={toggleMenu}
          className="p-2 w-[50px]  h-[50px] rounded-full outline-none lg:hidden"
          aria-expanded={menuVisible}
          aria-controls="sidebar-menu"
        >
          <img
            src={"https://picsum.photos/200/300?random=2"}
            alt=""
            className="rounded-full w-full h-full object-cover"
          />
        </button>
      </div>
      {showProfil && (
        <div
          onClick={handleCLoseProfil}
          className="absolute right-5 px-[10px] w-[150px] z-40 mt-[10px] rounded-lg bg-white border-slate-200 border shadow-md h-auto"
        >
          <button className="text-[14px] flex justify-between w-full items-center p-2">
            Profil
          </button>
          <button className="text-[14px] flex justify-between w-full items-center p-2">
            Pengaturan
          </button>
          <button className="text-[14px] flex justify-between w-full items-center p-2">
            Keluar
            {IconExit("14", "14", "red")}
          </button>{" "}
        </div>
      )}
      {/* Sidebar is always visible on lg screens */}
      <div
        ref={menuRef}
        className={`absolute w-[250px] lg:w-[270px]  top-0 right-0 lg:left-2 lg:top-[60px] lg:z-20  lg:block ${
          menuVisible ? "block" : "hidden"
        }`}
      >
        <ul
          id="sidebar-menu"
          className="list-none  bg-white lg:ml-[10px]   border-l lg:rounded-b-xl shadow-sm lg:shadow-md  h-screen lg:h-auto transition-all duration-300 ease-in-out rounded-l-lg flex flex-col justify-between"
        >
          <div>
            <li>
              <a
                href="#"
                className="block  rounded-t-xl transition-all duration-300"
                style={{
                  backgroundImage: `url("https://picsum.photos/200/300?random=7")`,
                }}
              >
                <div className="flex justify-center lg:flex-col lg:py-[10px] py-[30px] rounded-xl lg:rounded-t-xl items-center gap-3  bg-black bg-opacity-50 ">
                  <Link to={"/profile"}>
                    <div className="w-[80px] h-[80px]">
                      <img
                        src={"https://picsum.photos/200/300?random=2"}
                        alt=""
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                  </Link>
                  <div className=" text-white lg:text-center ">
                    <p className="text-[18px] font-semibold font-serif">
                      Abdul Majid
                    </p>
                    <p className="text-[14px]">Pelajar</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a
                href="/siswa/daftar-ujian"
                className="block py-2 px-4 hover:bg-gray-100 transition-all duration-300"
              >
                Ujian
              </a>
            </li>

            {/* Nilai Siswa Dropdown */}
            <li className="flex flex-col">
              <div
                className="flex justify-between py-2 px-4 hover:bg-gray-100 transition-all duration-300 cursor-pointer"
                onClick={() => toggleDropdown("nilaiSiswa")}
              >
                <span>Nilai Siswa</span>
                <span
                  className={`transform transition-transform duration-300 ${
                    activeDropdown === "nilaiSiswa" ? "rotate-0" : "-rotate-90"
                  }`}
                >
                  {IconChevron("24", "24", "black")}
                </span>
              </div>
              {activeDropdown === "nilaiSiswa" && (
                <ul className="list-none ">
                  <Link to={"/siswa/nilai-uts"}>
                    <li className="py-2 hover:bg-gray-200 pl-[30px]">
                      Nilai Murni UTS
                    </li>
                  </Link>
                  <Link to={"/siswa/nilai-uas"}>
                    <li className="py-2 hover:bg-gray-200 pl-[30px]">
                      Nilai Murni UAS
                    </li>
                  </Link>
                  <Link to={"/siswa/nilai-semester"}>
                    <li className="py-2 hover:bg-gray-200 pl-[30px]">
                      KHS Semester
                    </li>
                  </Link>
                </ul>
              )}
            </li>

            {/* Info Akademi Dropdown */}
            <li className="flex flex-col">
              <div
                className="flex justify-between py-2 px-4 hover:bg-gray-100 transition-all duration-300 cursor-pointer"
                onClick={() => toggleDropdown("infoAkademi")}
              >
                <span>Info Akademi</span>
                <span
                  className={`transform transition-transform duration-300 ${
                    activeDropdown === "infoAkademi" ? "rotate-0" : "-rotate-90"
                  }`}
                >
                  {IconChevron("24", "24", "black")}
                </span>
              </div>
              {activeDropdown === "infoAkademi" && (
                <ul className="list-none ">
                  <Link to={"/siswa/calender"}>
                    <li className="py-2 hover:bg-gray-200 pl-[30px]">
                      Kalender Akademi
                    </li>
                  </Link>
                  <Link to={"/siswa/jadwal-pelajaran"}>
                    <li className="py-2 hover:bg-gray-200 pl-[30px]">
                      Jadwal Pelajaran
                    </li>
                  </Link>
                  <Link to={"/siswa/mengajar"}>
                    <li className="py-2 hover:bg-gray-200 pl-[30px]">
                      Jadwal Guru Mengajar
                    </li>
                  </Link>
                </ul>
              )}
            </li>

            {/* Info Perpustakaan Dropdown */}
            <li className="flex flex-col">
              <div
                className="flex justify-between py-2 px-4 hover:bg-gray-100 transition-all duration-300 cursor-pointer"
                onClick={() => toggleDropdown("perpus")}
              >
                <span>Perpustakaan</span>
                <span
                  className={`transform transition-transform duration-300 ${
                    activeDropdown === "perpus" ? "rotate-0" : "-rotate-90"
                  }`}
                >
                  {IconChevron("24", "24", "black")}
                </span>
              </div>
              {activeDropdown === "perpus" && (
                <ul className="list-none ">
                  <Link to={"/siswa/list-buku"}>
                    <li className="py-2 hover:bg-gray-200 pl-[30px]">Buku</li>
                  </Link>
                  <Link to={"/siswa/status-pinjaman"}>
                    <li className="py-2 hover:bg-gray-200 pl-[30px]">
                      Pinjaman
                    </li>
                  </Link>
                </ul>
              )}
            </li>
            <li className="flex flex-col">
              <a
                href="/siswa/konseling"
                className="block py-2 px-4 hover:bg-gray-100 transition-all duration-300"
              >
                Konseling
              </a>
            </li>
          </div>

          <div className="lg:hidden mb-[20px] lg:mb-[70px]">
            <li>
              <a
                href="#"
                className="block py-2 px-4 hover:bg-gray-100 transition-all duration-300"
              >
                Pengaturan
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex justify-between py-2 px-4 hover:bg-red-100 transition-all duration-300 text-red-600"
              >
                Keluar
                {IconExit("24", "24", "red")}
              </a>
            </li>
          </div>
        </ul>
      </div>
    </section>
  );
};

export default Sidebar;
