import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Interface untuk data ujian
interface Exam {
  id: string;
  kodeUjian: string;
  mataPelajaran: string;
  waktuMulai: string;
  waktuBerakhir: string;
}

const ExamList: React.FC = () => {
  const navigate = useNavigate(); // Initialize navigate function

  // Data dummy untuk ujian
  const [examData, setExamData] = useState<Exam[]>([
    {
      id: "1",
      kodeUjian: "MATH123",
      mataPelajaran: "Matematika",
      waktuMulai: "2024-06-07T09:00:00",
      waktuBerakhir: "2024-09-07T15:00:00",
    },
    {
      id: "2",
      kodeUjian: "ENG456",
      mataPelajaran: "Bahasa Inggris",
      waktuMulai: "2024-09-08T13:00:00",
      waktuBerakhir: "2024-09-08T15:00:00",
    },
    {
      id: "3",
      kodeUjian: "SCI789",
      mataPelajaran: "IPA",
      waktuMulai: "2024-09-09T08:00:00",
      waktuBerakhir: "2024-09-09T10:00:00",
    },
  ]);

  const [currentTime, setCurrentTime] = useState(new Date());

  // Mengupdate waktu saat ini setiap detik
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Helper untuk mengecek apakah ujian sudah bisa dikerjakan
  const isExamAvailable = (waktuMulai: string) => {
    const examStartTime = new Date(waktuMulai);
    return currentTime >= examStartTime;
  };

  // Handler untuk navigasi ke halaman ujian
  const handleExamClick = () => {
    navigate("/siswa/ujian");
  };

  return (
    <div className="max-w-4xl  lg:absolute font-inter mx-auto p-4 md:p-6 bg-gray-100 rounded-lg shadow-lg">
      <h1 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-center text-blue-600">
        Daftar Ujian
      </h1>

      {/* Tabel untuk tampilan desktop */}
      <table className="hidden md:table lg:w-[800px] min-w-full bg-white border border-gray-300 rounded-lg overflow-hidden">
        <thead>
          <tr className="bg-blue-200 text-blue-800">
            <th className="py-3 px-6 border-b-2">Kode Ujian</th>
            <th className="py-3 px-6 border-b-2">Mata Pelajaran</th>
            <th className="py-3 px-6 border-b-2">Waktu Mulai</th>
            <th className="py-3 px-6 border-b-2">Waktu Berakhir</th>
            <th className="py-3 px-6 border-b-2">Aksi</th>
          </tr>
        </thead>
        <tbody>
          {examData.map((exam) => (
            <tr
              key={exam.id}
              className="text-center border-b border-gray-300 hover:bg-gray-50 transition duration-200"
            >
              <td className="py-3 px-6">{exam.kodeUjian}</td>
              <td className="py-3 px-6">{exam.mataPelajaran}</td>
              <td className="py-3 px-6">
                {new Date(exam.waktuMulai).toLocaleString("id-ID", {
                  dateStyle: "short",
                  timeStyle: "short",
                })}
              </td>
              <td className="py-3 px-6">
                {new Date(exam.waktuBerakhir).toLocaleString("id-ID", {
                  dateStyle: "short",
                  timeStyle: "short",
                })}
              </td>
              <td className="py-3 px-6">
                <button
                  className={`py-2 px-4 rounded-lg font-bold text-white transition-transform ${
                    isExamAvailable(exam.waktuMulai)
                      ? "bg-blue-500 hover:bg-blue-600 transform hover:scale-105"
                      : "bg-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!isExamAvailable(exam.waktuMulai)}
                  onClick={() =>
                    isExamAvailable(exam.waktuMulai) && handleExamClick()
                  }
                >
                  Kerjakan
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Tampilan mobile */}
      <div className="md:hidden space-y-4">
        {examData.map((exam) => (
          <div key={exam.id} className="bg-white p-4 rounded-lg shadow-md">
            <div className="grid grid-cols-2 gap-[10px]">
              <div className="mb-2">
                <span className="block font-bold text-blue-600">
                  Kode Ujian:
                </span>
                <span className="text-gray-700">{exam.kodeUjian}</span>
              </div>
              <div className="mb-2">
                <span className="block font-bold text-blue-600">
                  Mata Pelajaran:
                </span>
                <span className="text-gray-700">{exam.mataPelajaran}</span>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-[10px]">
              <div className="mb-2">
                <span className="block font-bold text-blue-600">
                  Waktu Mulai:
                </span>
                <span className="text-gray-700">
                  {new Date(exam.waktuMulai).toLocaleString("id-ID", {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </span>
              </div>
              <div className="mb-2">
                <span className="block font-bold text-blue-600">
                  Waktu Berakhir:
                </span>
                <span className="text-gray-700">
                  {new Date(exam.waktuBerakhir).toLocaleString("id-ID", {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </span>
              </div>
            </div>
            <button
              className={`w-full py-2 mt-4 rounded-lg font-bold text-white transition-transform ${
                isExamAvailable(exam.waktuMulai)
                  ? "bg-blue-500 hover:bg-blue-600 transform hover:scale-105"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
              disabled={!isExamAvailable(exam.waktuMulai)}
              onClick={() =>
                isExamAvailable(exam.waktuMulai) && handleExamClick()
              }
            >
              Kerjakan
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExamList;
