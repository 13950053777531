import React, { useState } from "react";

interface MarkData {
  no: number;
  mapel: string;
  nilai: number;
  kategori: "Wajib" | "Peminatan";
  semester: number;
}

const markData: MarkData[] = [
  { no: 1, mapel: "Matematika", nilai: 85, kategori: "Wajib", semester: 1 },
  {
    no: 2,
    mapel: "Bahasa Indonesia",
    nilai: 90,
    kategori: "Wajib",
    semester: 1,
  },
  { no: 3, mapel: "Fisika", nilai: 78, kategori: "Peminatan", semester: 1 },
  { no: 4, mapel: "Kimia", nilai: 88, kategori: "Peminatan", semester: 1 },
  { no: 5, mapel: "Matematika", nilai: 82, kategori: "Wajib", semester: 2 },
  {
    no: 6,
    mapel: "Bahasa Indonesia",
    nilai: 87,
    kategori: "Wajib",
    semester: 2,
  },
  { no: 7, mapel: "Biologi", nilai: 91, kategori: "Peminatan", semester: 2 },
  { no: 8, mapel: "Geografi", nilai: 80, kategori: "Peminatan", semester: 2 },
  { no: 9, mapel: "Matematika", nilai: 89, kategori: "Wajib", semester: 3 },
  {
    no: 10,
    mapel: "Bahasa Indonesia",
    nilai: 85,
    kategori: "Wajib",
    semester: 3,
  },
  { no: 11, mapel: "Fisika", nilai: 79, kategori: "Peminatan", semester: 3 },
  { no: 12, mapel: "Kimia", nilai: 86, kategori: "Peminatan", semester: 3 },
];

const SemesterGrades: React.FC = () => {
  const [selectedSemester, setSelectedSemester] = useState<number>(
    Math.max(...markData.map((mark) => mark.semester))
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const semesters = Array.from(new Set(markData.map((mark) => mark.semester)));

  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleSemesterSelect = (semester: number) => {
    setSelectedSemester(semester);
    setDropdownOpen(false);
  };

  const filteredData = markData.filter(
    (mark) => mark.semester === selectedSemester
  );

  const groupedData = filteredData.reduce(
    (acc, mark) => {
      if (!acc.groupedMarks[mark.kategori]) {
        acc.groupedMarks[mark.kategori] = [];
      }
      acc.groupedMarks[mark.kategori].push({ ...mark, no: acc.currentNo });
      acc.currentNo += 1;
      return acc;
    },
    { groupedMarks: {} as Record<string, MarkData[]>, currentNo: 1 }
  );

  return (
    <div className="container font-inter mx-auto p-6">
      <h1 className="text-3xl font-bold text-center mb-8 text-gray-800">
        Nilai Hasil Semester
      </h1>

      <div className="mb-6 flex justify-end relative">
        <button
          onClick={handleDropdownToggle}
          className="block w-[130px] p-2 bg-gray-100 border border-gray-300 rounded-md shadow text-sm text-gray-700"
        >
          Semester {selectedSemester}
        </button>
        {dropdownOpen && (
          <div className="absolute top-full right-0 mt-2 w-[130px] bg-white border border-gray-300 rounded-md shadow-lg z-10">
            {semesters.map((semester) => (
              <button
                key={semester}
                onClick={() => handleSemesterSelect(semester)}
                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                Semester {semester}
              </button>
            ))}
          </div>
        )}
      </div>

      <div className="bg-white shadow-lg rounded-lg border border-gray-200 overflow-x-auto">
        <div className="mb-6">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="py-3 px-6 text-left text-sm font-medium">No</th>
                <th className="py-3 px-6 text-left text-sm font-medium">
                  Mata Pelajaran
                </th>
                <th className="py-3 px-6 text-left text-sm font-medium">
                  Nilai
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {Object.entries(groupedData.groupedMarks).map(
                ([kategori, marks]) => (
                  <React.Fragment key={kategori}>
                    <tr>
                      <td
                        colSpan={3}
                        className="py-2 px-4 text-[16px] font-semibold bg-gray-100 text-gray-800 border-b border-gray-300"
                      >
                        {kategori}
                      </td>
                    </tr>
                    {marks.map((mark) => (
                      <tr
                        key={mark.no}
                        className=" transition-colors duration-300"
                      >
                        <td className="py-3 px-6 text-gray-800 text-sm">
                          {mark.no}
                        </td>
                        <td className="py-3 px-6 text-gray-800 text-sm">
                          {mark.mapel}
                        </td>
                        <td className="py-3 px-6 text-gray-800 text-sm">
                          {mark.nilai}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                )
              )}
            </tbody>
          </table>
        </div>

        {filteredData.length === 0 && (
          <div className="py-6 text-center text-gray-500">
            Data belum tersedia
          </div>
        )}
      </div>
      <div className="flex  justify-center mt-[20px] items-center h-full">
        <button className="w-full bg-gradient-to-r from-blue-400 to-blue-600 text-white font-semibold py-2 px-6 rounded-full shadow-lg hover:from-blue-500 hover:to-blue-700 transition ease-in-out duration-300">
          Download
        </button>
      </div>
    </div>
  );
};

export default SemesterGrades;
