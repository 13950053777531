import React, { useState } from "react";
import { IconClose } from "../../../../Assets/Icons";

// Dropdown component
interface DropdownProps {
  options: string[];
  onSelect: (value: string) => void;
  selectedValue: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  selectedValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="w-full p-3 border border-gray-300 bg-white rounded-lg focus:ring focus:ring-blue-300 focus:outline-none transition"
      >
        {selectedValue || "Pilih Kategori"}
      </div>
      {isOpen && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg shadow-lg mt-1">
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => {
                onSelect(option);
                setIsOpen(false);
              }}
              className="p-3 cursor-pointer hover:bg-blue-500 hover:text-white"
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
interface ComplainFormProps {
  onClose: () => void;
}

const ComplainForm: React.FC<ComplainFormProps> = ({ onClose }) => {
  const [selectedCounseling, setSelectedCounseling] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [complaint, setComplaint] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  // Dropdown options based on selected counseling
  const getCategoryOptions = () => {
    switch (selectedCounseling) {
      case "Konseling Akademik":
        return ["Kesulitan Belajar", "Pemilihan Jurusan", "Tekanan Akademik"];
      case "Konseling Sosial":
        return [
          "Pergaulan",
          "Konflik dengan Teman",
          "Adaptasi Lingkungan Baru",
        ];
      case "Konseling Emosional":
        return ["Kecemasan", "Depresi", "Perubahan Suasana Hati"];
      case "Konseling Personal":
        return ["Masalah Keluarga", "Kualitas Diri", "Masalah Kesehatan"];
      case "Konseling Lainnya":
        return ["Masalah Ekonomi", "Trauma", "Lain-lain"];
      default:
        return [];
    }
  };

  const handleComplaintSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Kategori:", selectedCategory, "Pengaduan:", complaint);
    if (image) {
      console.log("Image:", image.name);
    }
    setSelectedCounseling("");
    setSelectedCategory("");
    setComplaint("");
    setImage(null);
    setImagePreview(null);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleImageRemove = () => {
    if (imagePreview) {
      URL.revokeObjectURL(imagePreview);
    }
    setImage(null);
    setImagePreview(null);
  };

  return (
    <div className="bg-white w-full font-inter shadow-lg rounded-lg p-4 mx-auto">
      <div className="flex justify-between  mb-6 items-center">
        <h2 className="text-[20px] font-semibold text-gray-700">
          Pengaduan Konseling
        </h2>
        <button onClick={onClose}>{IconClose("24", "24", "black")}</button>
      </div>
      <form
        onSubmit={handleComplaintSubmit}
        className="space-y-4 text-[16px] overflow-x-auto h-[350px] "
      >
        {/* Counseling Type Dropdown */}
        <div>
          <label
            htmlFor="counseling"
            className="block mb-1 font-medium text-gray-600"
          >
            Jenis Konseling
          </label>
          <Dropdown
            options={[
              "Konseling Akademik",
              "Konseling Sosial",
              "Konseling Emosional",
              "Konseling Personal",
              "Konseling Lainnya",
            ]}
            selectedValue={selectedCounseling}
            onSelect={(value: string) => {
              setSelectedCounseling(value);
              setSelectedCategory("");
            }}
          />
        </div>

        {/* Category Dropdown (dynamic based on selected counseling) */}

        <div>
          <label
            htmlFor="category"
            className="block mb-1 font-medium text-gray-600"
          >
            Kategori Pengaduan
          </label>
          <Dropdown
            options={getCategoryOptions()}
            selectedValue={selectedCategory}
            onSelect={(value: string) => setSelectedCategory(value)}
          />
        </div>

        {/* Complaint Text Area */}
        <div>
          <label
            htmlFor="complaint"
            className="block mb-1 font-medium text-gray-600"
          >
            Pengaduan Anda
          </label>
          <textarea
            id="complaint"
            value={complaint}
            onChange={(e) => setComplaint(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-300 focus:outline-none transition"
            rows={5}
            placeholder="Tuliskan pengaduan Anda..."
            required
          />
        </div>

        {/* Image Upload */}
        <div>
          <label
            htmlFor="image"
            className="block text-[14px] mb-1 font-medium text-gray-600"
          >
            Tambahkan Gambar (Opsional)
          </label>
          <input
            id="image"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none"
          />
          {imagePreview && (
            <div className="relative mt-4 ">
              <img
                src={imagePreview}
                alt="Preview"
                className="w-full h-auto border border-gray-300 rounded-lg"
              />
              <button
                type="button"
                onClick={handleImageRemove}
                className="absolute top-2 right-2 bg-white p-1 rounded-full shadow-md focus:outline-none"
              >
                {IconClose("24", "24", "black")}
              </button>
            </div>
          )}
        </div>

        {/* Submit Button */}
        <div
          onClick={onClose}
          className="w-full text-center bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300 transition"
        >
          Kirim Pengaduan
        </div>
      </form>
    </div>
  );
};

export default ComplainForm;
