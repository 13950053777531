import React from "react";
import TruncateText from "../../../Components/truncateText";
import { Link } from "react-router-dom";

const DataValue = [
  {
    id: 1,
    background: "https://picsum.photos/200/300?random=1",
    profil: "https://picsum.photos/200/300?random=2",
    sekolah: "PT Karya Sindo",
    desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem accusantium vel harum voluptas cum praesentium illum nam ea id inventore, velit asperiores. Voluptate sint, in dolore qui assumenda    natus odit iste? Laboriosam dolores fugit vitae incidunt molestias  labore dolorum maxime quam, ex at, ipsam repudiandae nemo, quasi et       aliquam fuga!",
  },
  {
    id: 2,
    background: "https://picsum.photos/200/300?random=3",
    profil: "https://picsum.photos/200/300?random=4",
    sekolah: "PT Alam Nuansa",
    desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem accusantium vel harum voluptas cum praesentium illum nam ea id inventore, velit asperiores. Voluptate sint, in dolore qui assumenda    natus odit iste? Laboriosam dolores fugit vitae incidunt molestias  labore dolorum maxime quam, ex at, ipsam repudiandae nemo, quasi et       aliquam fuga!",
  },
  {
    id: 3,
    background: "https://picsum.photos/200/300?random=5",
    profil: "https://picsum.photos/200/300?random=6",
    sekolah: "PT Tirta Jaya",
    desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem accusantium vel harum voluptas cum praesentium illum nam ea id inventore, velit asperiores. Voluptate sint, in dolore qui assumenda    natus odit iste? Laboriosam dolores fugit vitae incidunt molestias  labore dolorum maxime quam, ex at, ipsam repudiandae nemo, quasi et       aliquam fuga!",
  },
  {
    id: 4,
    background: "https://picsum.photos/200/300?random=7",
    profil: "https://picsum.photos/200/300?random=8",
    sekolah: "PT Jaya Abadi",
    desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem accusantium vel harum voluptas cum praesentium illum nam ea id inventore, velit asperiores. Voluptate sint, in dolore qui assumenda    natus odit iste? Laboriosam dolores fugit vitae incidunt molestias  labore dolorum maxime quam, ex at, ipsam repudiandae nemo, quasi et       aliquam fuga!",
  },
];

const CompanyResults: React.FC = () => (
  <section className="font-inter">
    {DataValue.map((item, index) => (
      <div
        key={index}
        className="border p-4 mb-4 rounded-lg shadow-md bg-purple-50"
      >
        <div className="w-full h-[130px]">
          <img
            src={item.background}
            alt=""
            className="w-full h-full object-cover mb-2 rounded-lg"
          />
        </div>
        <div className="flex justify-between">
          <div className="h-[80px] w-[80px]  mt-[-50px] ml-[10px]">
            <img
              src={item.profil}
              alt=""
              className="w-full h-full object-cover rounded-full"
            />
          </div>
          <div className=" bg-blue-500 py-1 px-6 rounded-md text-[14px] shadow font-inter text-white mt-[5px]">
            <button>Ikuti</button>
          </div>
        </div>
        <Link to={"/detail-perusahaan"}>
          <h2 className="text-xl font-semibold mb-2">{item.sekolah}</h2>
          <p className="text-gray-700 text-[13px]">
            {TruncateText(item.desc, 100)}
          </p>
        </Link>
      </div>
    ))}
  </section>
);

export default CompanyResults;
