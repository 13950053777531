import React, { useState } from "react";
import { IconClose, IconMinus, IconPlus } from "../../../Assets/Icons";

interface BorrowBookProps {
  onclose: () => void;
}
const BorrowBooks: React.FC<BorrowBookProps> = ({ onclose }) => {
  const [durationIndex, setDurationIndex] = useState(0);

  // Pilihan durasi
  const durations = ["3 hari", "1 minggu", "1 bulan", "1 semester"];

  // Fungsi untuk menambah durasi
  const increaseDuration = () => {
    if (durationIndex < durations.length - 1) {
      setDurationIndex(durationIndex + 1);
    }
  };

  // Fungsi untuk mengurangi durasi
  const decreaseDuration = () => {
    if (durationIndex > 0) {
      setDurationIndex(durationIndex - 1);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-end">
      <div className="bg-white w-full p-4 h-[280px]">
        <button onClick={onclose} className="flex w-full justify-end">
          {IconClose("24", "24", "black")}
        </button>
        <div className="">
          <div className="flex border border-slate-400 p-[10px] shadow-sm rounded-lg">
            <div className="w-[100px] h-[60px] mr-2">
              <img
                src="https://picsum.photos/200/300?random=4"
                alt="Book cover"
                className="w-full h-full rounded-lg  object-cover"
              />
            </div>
            <div>
              <p className="text-[14px]">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
              <p className="text-[14px] text-right text-slate-400">
                Stok : <span>30</span>
              </p>
            </div>
          </div>

          {/* Kontrol Durasi dengan tombol + dan - */}
          <div className=" items-center flex mt-[10px] justify-between">
            <p className="mr-2 ">Durasi</p>
            <div className="flex items-center">
              <button
                className=""
                onClick={decreaseDuration}
                disabled={durationIndex === 0}
              >
                {IconMinus("24", "24", "black")}
              </button>
              <p className="mx-2 text-center w-[80px] text-[16px]">
                {durations[durationIndex]}
              </p>
              <button
                className=""
                onClick={increaseDuration}
                disabled={durationIndex === durations.length - 1}
              >
                {IconPlus("24", "24", "black")}
              </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-[10px]">
          <button className="mt-4 w-full bg-blue-500 text-white py-2 rounded-md">
            Dipesan
          </button>{" "}
          <button className="mt-4 w-full bg-blue-500 text-white py-2 rounded-md">
            Ajukan
          </button>
        </div>
      </div>
    </div>
  );
};

export default BorrowBooks;
