import React, { useState } from "react";
import BorrowBooks from "./borrowBooks.tsx";
import { IconSearch2 } from "./icons/index.js";

// Interface Book
export interface Book {
  id: number;
  title: string;
  author: string;
  year: number;
  isBestSeller: boolean;
  isNewRelease: boolean;
  image: string; // URL gambar buku
}

// Data Dummy Buku
const books: Book[] = [
  {
    id: 1,
    title: "Matematika Perminatan",
    author: "Author One",
    year: 2021,
    isBestSeller: true,
    isNewRelease: false,
    image: "https://picsum.photos/200/300?random=1",
  },
  {
    id: 2,
    title: "Book Two",
    author: "Author Two",
    year: 2023,
    isBestSeller: false,
    isNewRelease: true,
    image: "https://picsum.photos/200/300?random=2",
  },
  {
    id: 3,
    title: "Book Three",
    author: "Author Three",
    year: 2022,
    isBestSeller: true,
    isNewRelease: false,
    image: "https://picsum.photos/200/300?random=3",
  },
  {
    id: 4,
    title: "Book Four",
    author: "Author Four",
    year: 2023,
    isBestSeller: false,
    isNewRelease: true,
    image: "https://picsum.photos/200/300?random=4",
  },
  {
    id: 5,
    title: "Book Five",
    author: "Author Five",
    year: 2020,
    isBestSeller: true,
    isNewRelease: false,
    image: "https://picsum.photos/200/300?random=5",
  },
];

const ListBooks: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<
    "all" | "bestSeller" | "newRelease"
  >("all");

  const [showBorrow, setShowBorrow] = useState(false);

  const HandleOpenBorrow = () => {
    setShowBorrow(true);
  };

  const handleCloseBorrow = () => {
    setShowBorrow(false);
  };

  const filterBooks = (filter: "all" | "bestSeller" | "newRelease") => {
    switch (filter) {
      case "bestSeller":
        return books.filter((book) => book.isBestSeller);
      case "newRelease":
        return books.filter((book) => book.isNewRelease);
      default:
        return books;
    }
  };

  const displayedBooks = filterBooks(selectedCategory);

  return (
    <div className=" mx-auto font-inter lg:absolute lg:w-[1000px] lg:ml-[30px]">
      <div className="fixed w-full bg-white z-20 border-b lg:mt-[-12px]">
        <div className="p-4">
          <h1 className="text-3xl font-bold  ">Daftar Buku</h1>
          {/* <div>{IconRansel("24", "24", "gray")}</div> */}
        </div>
        {/* Filter Options */}

        <div className="flex lg:ml-[20px] justify-center lg:justify-start  pb-[10px] space-x-4">
          <div>
            <input
              type="text"
              className="border-slate-400 outline-none border lg:rounded-full lg:py-1 lg:px-4"
            />
          </div>
          <button
            onClick={() => setSelectedCategory("all")}
            className={`px-6 py-1 rounded-full border transition-colors duration-300 
            ${
              selectedCategory === "all"
                ? "bg-blue-500 text-white border-blue-500"
                : "bg-white text-blue-500 border-blue-500 "
            }`}
          >
            Semua
          </button>
          <button
            onClick={() => setSelectedCategory("bestSeller")}
            className={`px-6 outline-none py-1 rounded-full border transition-colors duration-300 
            ${
              selectedCategory === "bestSeller"
                ? "bg-blue-500 text-white border-blue-500"
                : "bg-white text-blue-500 border-blue-500 "
            }`}
          >
            Terlaris
          </button>
          <button
            onClick={() => setSelectedCategory("newRelease")}
            className={`px-6 py-1 rounded-full border transition-colors duration-300 
            ${
              selectedCategory === "newRelease"
                ? "bg-blue-500 text-white border-blue-500"
                : "bg-white text-blue-500 border-blue-500 "
            }`}
          >
            Terbaru
          </button>
        </div>
      </div>

      {/* Book List */}
      <div className="grid grid-cols-2 px-4  pt-[130px]  lg:grid-cols-4 lg:gap-4 gap-2">
        {displayedBooks.map((book) => (
          <div
            key={book.id}
            className="bg-white rounded-lg shadow-md overflow-hidden transition transform hover:scale-105"
          >
            <div className="h-[100px]">
              <img
                src={book.image}
                alt={book.title}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="p-2">
              <h2 className="text-[14px]  text-gray-600 font-semibold mb-2 h-[40px]">
                {book.title}
              </h2>
              <p className="text-gray-600 text-[12px]">
                Penulis: {book.author}
              </p>
              <p className="text-gray-600 text-[12px]">Tahun: {book.year}</p>
              <div className="mt-2 justify-between flex space-x-2">
                {book.isBestSeller && (
                  <span className="px-2 py-1 bg-green-200 text-green-800 text-xs rounded-full">
                    Terlaris
                  </span>
                )}
                {book.isNewRelease && (
                  <span className="px-2 py-1 bg-red-200 text-red-800 text-xs rounded-full">
                    Terbaru
                  </span>
                )}
                <div className="text-gray-600 text-[12px]">
                  <p>Stok : 12</p>
                </div>
              </div>

              <button
                onClick={HandleOpenBorrow}
                className="w-full text-white font-semibold mt-[10px] text-center text-[14px] bg-blue-600 py-[3px] rounded-full"
              >
                Pinjam
              </button>
            </div>
          </div>
        ))}
      </div>
      {showBorrow && <BorrowBooks onclose={handleCloseBorrow} />}

      {/* No Books Found */}
      {displayedBooks.length === 0 && (
        <div className="text-center mt-8 text-gray-500">
          Tidak ada buku yang ditemukan dalam kategori ini.
        </div>
      )}
    </div>
  );
};

export default ListBooks;
