import React, { useState } from "react";
import ProfileSekolah from "./profileSekolah";
import Extrakulikuler from "../../../Exrakulikuler";
import ProgramSchool from "../../../programSchool";

const DetailSchool = () => {
  const [activeTab, setActiveTab] = useState("post");

  const renderActiveComponent = () => {
    switch (activeTab) {
      case "tentang":
        return <ProfileSekolah />;
      case "extrakulikuler":
        return <Extrakulikuler />;
      case "program":
        return <ProgramSchool />;
      case "post":
        return <div></div>;
      default:
        return null;
    }
  };

  return (
    <main>
      <section>
        <div className="w-full h-[100px]">
          <img
            src={"https://picsum.photos/200/300?random=3"}
            alt="Background"
            className="h-full w-full object-cover"
          />
        </div>
        <div className="flex gap-4">
          <div className="w-[100px] mt-[-35px] h-[100px] ml-[20px]">
            <img
              src={"https://picsum.photos/200/300?random=8"}
              alt="Profile"
              className="w-full h-full object-cover rounded-full"
            />
          </div>
          <div className="flex  items-center gap-4 text-[20px] font-roboto font-bold">
            <h1>
              Akreditasi <span className="text-[24px] text-red-800">A</span>
            </h1>
          </div>
        </div>
        <div className="p-4">
          <p className="text-[20px] font-bold">SMAN 1 Pedes Karawang</p>
        </div>

        {/* pada bagian ini lebarnya samakan */}
        <div className="overflow-x-auto  mx-[20px]">
          <div className="flex gap-2">
            <p
              className={`${
                activeTab === "post"
                  ? "bg-blue-800 text-white"
                  : "border border-blue-800 text-blue-800"
              } shadow rounded-lg py-[5px] px-[10px] cursor-pointer`}
              onClick={() => setActiveTab("post")}
            >
              Postingan
            </p>
            <p
              className={`${
                activeTab === "tentang"
                  ? "bg-blue-800 text-white"
                  : "border border-blue-800 text-blue-800"
              } shadow rounded-lg py-[5px] px-[10px] cursor-pointer`}
              onClick={() => setActiveTab("tentang")}
            >
              Tentang
            </p>
            <p
              className={`${
                activeTab === "extrakulikuler"
                  ? "bg-blue-800 text-white"
                  : "border border-blue-800 text-blue-800"
              } shadow rounded-lg py-[5px] px-[10px] cursor-pointer`}
              onClick={() => setActiveTab("extrakulikuler")}
            >
              Exstrakulikuler
            </p>
            <p
              className={`${
                activeTab === "program"
                  ? "bg-blue-800 text-white"
                  : "border border-blue-800 text-blue-800"
              } shadow rounded-lg py-[5px] px-[40px]  cursor-pointer`}
              onClick={() => setActiveTab("program")}
            >
              Program
            </p>
            {/* Tambahkan lebih banyak tab jika diperlukan */}
          </div>
        </div>
        <div className="mt-[20px] mx-[20px]">{renderActiveComponent()}</div>
      </section>
    </main>
  );
};

export default DetailSchool;
